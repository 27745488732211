import {
    DASHBOARD_FETCH,
    WEEKS_FETCH,
    WEEK_FETCH, DASHBOARD_ASYNC_FETCH
} from './consts';

import Vue from 'vue';
import {DateTime} from 'luxon';

export default {
    [WEEKS_FETCH](state, payload) {
        Vue.set(state, 'weeks', payload.data.map((week) => {
            const beginAt = week.beginAt && DateTime.fromISO(week.beginAt, {locale: 'ru', setZone: true});
            const endAt = week.endAt && DateTime.fromISO(week.endAt, {locale: 'ru', setZone: true});

            return {
                ...week,
                beginAt: beginAt,
                endAt: endAt
            };
        }));
    },
    [WEEK_FETCH](state, payload) {
        // console.log(payload.data);
        Vue.set(state, 'week', payload.data);
    },
    [DASHBOARD_FETCH](state, payload) {
        Vue.set(state, 'quick', {
            ...payload.data,
            webinar: payload.data.webinar && {
                ...payload.data.webinar,
                datetime: DateTime.fromISO(payload.data.webinar.datetime, {locale: 'ru', setZone: true})
            } || undefined
        });
    },
    [DASHBOARD_ASYNC_FETCH](state, payload) {
        Vue.set(state, 'asyncEnrollments', payload.data);
    }
};

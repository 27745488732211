import {DATA_LOADED_CHANGE} from 'store';

export default {
    created() {
        const mutations = this.mutations || [];

        this.$store.subscribe((mutation) => {
            if (mutations.findIndex(item => mutation.type === item) !== -1) this.$store.state.mutationsCommited++;

            if (this.$store.state.mutationsCommited === mutations.length && !this.$store.state.dataLoaded) {
                this.$store.commit(DATA_LOADED_CHANGE, true);
            }
        });
    }
};

import Vue from 'vue';
import Urls from 'django_js_front/reverse.js';

import {DateTime, Interval} from 'luxon';

import SvgView from './components/SvgView.vue';

import VueAppend from 'vue-append';
import {HandleDirective} from 'vue-slicksort';
import debounce from 'v-debounce';
import ClickOutside from 'vue-click-outside';

import {isMobileOnly, isMobileSafari} from 'mobile-device-detect';

let cid = 1;

Object.defineProperty(Array.prototype, 'last', {
    enumerable: false,
    configurable: false,
    writable: false,
    value: function () {
        return this[this.length - 1];
    }
});

String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
};

export function mapPreview(item) {
    if (item && item.preview && item.preview.isImage) {
        return item.preview.source.url;
    }
}

export function formatRange(beginAt, endAt) {
    const now = DateTime.local();
    // const intervalAt = beginAt && endAt && Interval.fromDateTimes(beginAt, endAt);

    let dates = null;
    let beginFormat = 'dd MMMM yyyy';
    let endFormat = 'dd MMMM yyyy';

    if (beginAt) {
        if (endAt && beginAt.hasSame(endAt, 'year')) {
            if (endAt && beginAt.hasSame(endAt, 'month')) {
                beginFormat = 'dd';
                endFormat = 'dd MMMM';
            } else {
                beginFormat = 'dd MMMM';
                endFormat = 'dd MMMM';
            }

            if (!beginAt.hasSame(now, 'year')) {
                endFormat += ' yyyy';
            }
        } else {
            if (!endAt && beginAt.hasSame(now, 'year')) {
                beginFormat = 'dd MMMM';
            }
        }

        if (+beginAt === +endAt) dates = endAt.toFormat(endFormat);
        else dates = beginAt.toFormat(beginFormat) + (endAt ? ' – ' + endAt.toFormat(endFormat) : '');
    }

    return dates;
}

export function calculateProgress(beginAt, endAt) {
    const now = DateTime.local();

    if (!beginAt || !endAt || beginAt > now) {
        return 0;
    }

    const courseInterval = Interval.fromDateTimes(beginAt, endAt).length('days');
    const nowInterval = Interval.fromDateTimes(beginAt, now).length('days');

    let progress = Math.floor(nowInterval / courseInterval * 100);

    if (progress > 100) {
        progress = 100;
    }

    return progress;
}

export function generateUID(prefix = '') {
    return prefix + Math.floor(Math.random() * 1000000);
}

const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/webp'];

export function checkFileTypeIsImage(type) {
    return acceptedImageTypes.includes(type);
}

// Get click coordinates in percents
export function getElementClickCoordinates(event, el) {
    const elRect = el.getBoundingClientRect();

    return {
        x: parseFloat(((event.pageX - elRect.x) / elRect.width * 100).toFixed(3)),
        y: parseFloat(((event.pageY - elRect.y) / elRect.height * 100).toFixed(3))
    };
}

export function getLinesCount(text = '') {
    const lines = text.split(/\r|\r\n|\n/);

    return lines.length;
}

export function absMax() {
    // Convert arguments to Array
    const args = Array.prototype.slice.call(arguments);

    return args.reduce((max, value) => {
        let res;

        if (Math.abs(max) >= Math.abs(value)) res = max;
        else res = value;

        return res;
    });
}

export function transformTimeToUntiFormat(timeInSeconds = 0) {
    const hhmmss = new Date(timeInSeconds * 1000)
        .toISOString()
        .substring(11, 19);

    const hhmmssArray = hhmmss.split(':');

    const hoursNumber = +hhmmssArray[0];
    const minutesNumber = +hhmmssArray[1];
    const secondsNumber = +hhmmssArray[2];

    let res = `${secondsNumber}S`;

    if (minutesNumber !== 0 || hoursNumber !== 0) {
        res = `${minutesNumber}M${res}`;
    }

    if (hoursNumber !== 0) {
        res = `${hoursNumber}H${res}`;
    }

    res = `T${res}`;

    return res;
}

Vue.mixin({
    directives: {sort: HandleDirective, debounce, ClickOutside},
    components: {
        SvgView
    },
    data: function () {
        return {
            ...(app.settings || {}),
            Urls
        };
    },
    computed: {
        documentTitle() {
            return this.makeDocumentTitle();
        },
        isMobileOnly() {
            return isMobileOnly;
        },
        isMobileSafari() {
            return isMobileSafari;
        }
    },
    methods: {
        static(url) {
            return app.settings.staticUrl + url;
        },
        urlStatic(url) {
            return `url('${app.settings.staticUrl + url}'`;
        },
        generateCid() {
            return `cid${++cid}`;
        },
        reorder(list) {
            return list.map((item, index) => ({...item, order: index}));
        },
        reorderObject(list) {
            return {...this.reorder(Object.values(list))};
        },
        getCountableWordForm(count, forms) {
            const modulo = count % 10;
            let result;

            if (modulo === 1) result = forms[0];
            else if (modulo >= 2 && modulo <= 4) result = forms[1];
            else result = forms[2];

            return result;
        }
    }
});

Vue.use(VueAppend);

Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            // here I check that click was outside the el and his childrens
            if (!(el === event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
    }
});

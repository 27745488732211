import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    courses: [],
    course: {
        modules: []
    },
    lesson: {
        schedule: {
            enrollment: {}
        },
        homework: {
            works: []
        }
    },
    uploading: {
        loading: false,
        uploading: false,
        created: false,
        files: [],
        error: ''
    },
    homeworks: [],
    homework: {
        files: []
    },
    webinar: {},
    lessonSession: {
        error: ''
    },
    comments: []
};

export * from './consts';

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state
};

import {DateTime} from 'luxon';

import {
    COURSE_STATUS,
    WORK_STATUSES
} from './consts';

const now = DateTime.local().startOf('day');

export default {
    statusedCourses: (state) => {
        const courses = {
            [COURSE_STATUS.Active]: [],
            [COURSE_STATUS.Past]: [],
            [COURSE_STATUS.Future]: []
        };

        for (const course of state.courses) {
            let courseStatus;

            if (course.isAsync) {
                if (course.isActive || !course.isStudent) {
                    courseStatus = COURSE_STATUS.Active;
                } else {
                    courseStatus = COURSE_STATUS.Past;
                }
            } else {
                courseStatus = course.status;
            }

            courseStatus && courses[courseStatus].push(course);
        }

        return courses;
    },
    categories: (state) => {
        const slugs = [];
        const categories = [];

        state.courses.forEach((course) => {
            if (!course.categories) {
                return;
            }

            course.categories.forEach((category) => {
                if (slugs.indexOf(category.slug) !== -1) {
                    return;
                }
                slugs.push(category.slug);
                categories.push(category);
            });
        });

        return categories;
    },
    types: (state) => {
        const codes = [];
        const types = [];

        state.courses.forEach((course) => {
            if (!course.typeCode) {
                return;
            }

            if (codes.indexOf(course.typeCode) === -1) {
                types.push({
                    code: course.typeCode,
                    name: course.type
                });
                codes.push(course.typeCode);
            }
        });

        return types;
    },
    canSubmit: (state) => {
        return !state.uploading.loading && !state.uploading.uploading;
    },
    canUpload: (state) => {
        return state.uploading.loading && state.uploading.created && !state.uploading.uploading;
    },
    workUploaded: (state) => {
        return state.uploading.created && (!state.uploading.loading || !state.uploading.files.length);
    },
    uploadError: (state) => {
        return state.uploading.error;
    },
    uploadFilesSize(state) {
        return state.uploading.files.reduce((res, file) => res + file.size, 0);
    },
    uploadedFilesSize(state) {
        return state.uploading.files.reduce((res, file) => {
            return !!file.file ? file.size + res : res;
        }, 0);
    },
    uploadProgress(state, getters) {
        return getters.uploadFilesSize && getters.uploadedFilesSize / getters.uploadFilesSize;
    },
    statusedWorks(state) {
        const works = {
            [WORK_STATUSES.review]: [],
            [WORK_STATUSES.revision]: [],
            [WORK_STATUSES.accepted]: [],
            [WORK_STATUSES.unuploaded]: []
        };

        for (const work of state.homeworks) {
            works[work.status].push(work);
        }

        return works;
    },

    currentLessons(state) {
        let lessons = [];
        let enrollment = {};
        for (const module of state.course.modules || []) {
            if (module.beginAt > now || module.endAt < now) continue;

            for (const week of module.weeks) {
                if (week.beginAt > now || week.endAt < now) continue;

                const weekEnrollment = week.enrollments[0];

                enrollment = {
                    id: weekEnrollment.id,
                    slug: weekEnrollment.slug
                };

                for (const theme of weekEnrollment.themes) {
                    lessons = lessons.concat(theme.lessons.filter(lesson => lesson.hasHomework));
                }
            }
        }

        return {
            lessons,
            enrollment
        };
    },

    currentModuleInd(state) {
        const modules = state.course.modules || [];
        // By default return index of last module
        let ind = modules.length - 1;

        for (let i = 0; i < modules.length; i++) {
            if (modules[i].beginAt <= now && modules[i].endAt >= now) ind = i;
        }

        return ind;
    },
    categorizedCourses: (state) => {
        const categorizedCourses = {};

        for (const course of state.courses) {
            for (const category of course.categories) {
                if (categorizedCourses[category.slug]) categorizedCourses[category.slug].push(course);
                else categorizedCourses[category.slug] = [course];
            }
        }

        return categorizedCourses;
    },
    homeworkTeacher(state) {
        return state.homework.teacher;
    }
};

import {
    ITEM_FETCH,
    ITEM_SAVE,
    ITEM_PASSWORD
} from './consts';

import Vue from 'vue';
import {DateTime} from 'luxon';

export default {
    [ITEM_FETCH](state, payload) {
        const activity = DateTime.fromISO(payload.data.activity, {locale: 'ru'}).toFormat('dd MMMM, yyyy');

        Vue.set(state, 'profile', {
            ...payload.data,
            activity
        });
    },
    [ITEM_SAVE](state) {
        state.uploading.resolve();
    },
    [ITEM_PASSWORD](state) {
        console.log(state);
    }
};

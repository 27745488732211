export const WORK_STATUSES_INFO = {
    1: {
        name: 'На проверке',
        multiple: 'На проверку',
        field: 'review'
    },
    2: {
        name: 'На доработке',
        multiple: 'На доработке',
        editor: 'На доработку',
        field: 'revision'
    },
    3: {
        name: 'Принята',
        multiple: 'Принятые',
        editor: 'Работа принята',
        field: 'accepted'
    },
    4: {
        name: 'Не сдана',
        multiple: 'Не сдали',
        field: 'unUploaded'
    }
};

export const WORK_STATUSES = {
    review: 1,
    revision: 2,
    accepted: 3,
    unUploaded: 4
};


export const COURSE_KIND_NAMES = {
    1: {
        single: 'Онлайн-курс',
        multiple: 'Курсы'
    },
    2: {
        single: 'Интенсив',
        multiple: 'Интенсивы'
    },
    3: {
        single: 'Годовая программа',
        multiple: 'Годовые программы'
    }
};

export const RECENTLY_FINISHED_COURSE_DAYS_DELTA = 30;

export const INPUT_TYPES = {
    text: 'text',
    password: 'password',
    radio: 'radio',
    file: 'file'
};

export const WORK_WORD_FORMS = ['работа', 'работы', 'работ'];

export const REMARK_WORD_FORMS = ['замечание', 'замечания', 'замечаний'];

export const COMMENT_WORD_FORMS = ['комментарий', 'комментария', 'комментариев'];

export const VIDEO_REGEXS = {
    youtube: /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/|live\/)?)(?<videoId>[\w\-]+)(\S+)?$/,
    vimeo: /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:\/(\w+))?(?:|\/\?)/,
    kinescope: /^.*(kinescope\.io\/)(embed\/)?(?<videoId>[^\/]+)(\/)?/
};

export const VIDEO_PROVIDERS = {
    youtube: 'youtube',
    vimeo: 'vimeo',
    kinescope: 'kinescope'
};

export const OPTION_ALL = 'all';

export const CLASS_PREVENT_SCROLL = 's-scroll-prevent';


export const DEBOUNCE_DELAY = 200;

export const DEVICE_SIZES = {
    phone: {
        max: 767
    }
};

export const LOCALSTORAGE_DURATIONS = 'durations';

export const SEND_DURATIONS_INTERVAL = 300000;
export const CHECK_PLAYER_READINESS_TIMEOUT = 100;
export const PLAYER_UPDATE_EVENT_TYPES = {
    play: {
        type: 'play',
        untiType: 'play'
    },
    pause: {
        type: 'pause',
        untiType: 'paused'
    },
    changePlaybackSpeed: {
        type: 'change-playback-speed',
        untiType: 'change-playback-speed'
    },
    seekEnd: {
        type: 'seek-end',
        untiType: 'rewind'
    },
    completed: {
        type: 'completed',
        untiType: 'completed'
    },
    skipped: {
        type: 'skipped',
        untiType: 'skipped'
    }
};


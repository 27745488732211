<template>
    <div class="Recovery">
        <div class="Recovery-nav">
            <router-link class="Recovery-logo" :to="{name: 'login'}">
                <logo :mods="['dark']"/>
            </router-link>
            <page-link class="Recovery-back" route="login">Экран логина</page-link>
        </div>
        <div class="Recovery-content">
            <div class="Recovery-contentInner">
                <div class="Recovery-info" v-if="isWithToken">
                    <div class="Recovery-title s-text-label">Новый пароль</div>
                    <div class="Recovery-description s-text-title">Убедитесь, что ваш новый пароль надежен</div>
                </div>
                <div class="Recovery-info" v-else>
                    <div class="Recovery-title s-text-common">Восстановить пароль</div>
                    <div class="Recovery-description s-text-title">
                        На вашу почту придет письмо с ссылкой на восстановление пароля
                    </div>
                </div>
                <form class="Recovery-form s-form" @submit.prevent="onSubmit">
                    <input-view label="Пароль" placeholder="Введите пароль" class="Recovery-input" :mods="['big']"
                                v-model="value" type="password" v-if="isWithToken"/>
                    <input-view label="Почта" placeholder="example@gmail.com" class="Recovery-input" :mods="['big']"
                                v-model="value" v-else/>
                    <div class="Recovery-bottom">
                        <button class="Recovery-submit s-form-submit s-button--primary" type="submit" :disabled="!submitEnabled">
                            {{ isWithToken ? 'Сохранить' : 'Отправить' }}
                        </button>
                        <div class="Recovery-note s-text-caption" :class="{isError: error}" v-text="noteText"/>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';

    import Logo from 'front/components/Logo.vue';
    import InputView from 'front/components/InputView.vue';
    import PageLink from 'front/components/PageLink.vue';

    export default {
        name: 'RecoveryPage',

        mixins: [mixins.DocumentTitleMixin],

        components: {
            Logo,
            InputView,
            PageLink
        },

        data: function () {
            return {
                value: '',
                submitEnabled: false,
                error: '',
                success: false
            };
        },

        computed: {
            documentTitle() {
                return this.makeDocumentTitle('Восстановление пароля');
            },

            // If has token url, then password can be reset
            isWithToken() {
                return !!this.$route.params.token;
            },

            noteText() {
                const defaultText = 'Новый пароль будет изменен на основном сайте bangbangeducation.com';
                const sendText = 'Отправили письмо на указанную почту';
                let note;

                if (this.error) note = this.error;
                else note = this.success ? sendText : defaultText;

                return note;
            }
        },

        watch: {
            value() {
                this.submitEnabled = !!this.value;
                this.error = '';
            }
        },

        methods: {
            onSubmit() {
                const url = this.isWithToken ? Urls['lms:api:reset']() : Urls['lms:api:recovery']();
                const fieldName = this.isWithToken ? 'password' : 'email';
                let data = {
                    [fieldName]: this.value
                };

                if (this.isWithToken) data.token = this.$route.params.token;

                this.submitEnabled = false;

                axios.post(url, data)
                    .then(() => {
                        if (this.isWithToken) window.location = Urls['lms:login']();

                        this.error = '';
                        if (!this.isWithToken) this.success = true;
                    })
                    .catch((error) => this.error = error.response.data.detail);
            }
        }
    };
</script>

<style scoped lang="less">
    @import "~styles/const.less";

    .Recovery {
        width: 100%;
        height: 100vh;
        min-height: 600px;

        @media @phone {
            min-height: 500px;
        }

        &-nav {
            position: fixed;
            left: 40px;
            top: 0;
            width: 290px;
            height: 100vh;
            padding-top: 16px;
            border-right: 1px solid @color-gray--light;

            @media @desktop--big {
                width: 400px;
                padding-top: 24px;
            }

            @media @laptop {
                width: 210px;
                padding-top: 12px;
            }

            @media @laptop--small {
                left: 28px;
                width: 148px;
                padding-top: 8px;
            }

            @media @phone {
                width: auto;
                height: auto;
                left: 24px;
                border-right: none;
            }
        }

        &-logo {
            display: block;
            margin-bottom: 44px;

            @media @desktop--big {
                margin-bottom: 56px;
            }

            @media @laptop {
                margin-bottom: 36px;
            }

            @media @laptop--small {
                margin-bottom: 24px;
            }

            @media @phone {
                margin-bottom: 0;
            }
        }

        &-back {
            @media @phone {
                display: none;
            }
        }

        &-content {
            position: relative;
            height: 100%;
            margin-left: 330px;
            padding: 104px 330px 0;

            @media @desktop--big {
                margin-left: 436px;
                padding: 140px 436px 0;
            }

            @media @laptop {
                margin-left: 250px;
                padding: 80px 250px 0;
            }

            @media @laptop--small {
                margin-left: 180px;
                padding: 56px 192px 0;
            }

            @media @phone {
                margin: 0 24px;
                padding: 76px 0 36px;
            }

            &Inner {
                position: relative;
                height: 100%;
                max-width: 910px;
                margin: 0 auto;
            }
        }

        &-info {
            color: @color-black;
            padding-bottom: 40px;
            margin-bottom: 28px;
            border-bottom: 1px solid @color-gray--light;

            @media @desktop--big {
                padding-bottom: 56px;
                margin-bottom: 40px;
            }

            @media @laptop {
                padding-bottom: 32px;
                margin-bottom: 20px;
            }

            @media @laptop--small {
                padding-bottom: 24px;
                margin-bottom: 16px;
            }

            @media @phone {
                padding-bottom: 24px;
                margin-bottom: 20px;
            }
        }

        &-title {
            margin-bottom: 12px;

            @media @desktop--big {
                margin-bottom: 16px;
            }

            @media @laptop {
                margin-bottom: 8px;
            }

            @media @laptop--small {
                margin-bottom: 4px;
            }

            @media @phone {
                margin-bottom: 4px;
            }
        }

        &-description {
            @media @desktop--big {
                max-width: 808px;
            }

            @media @desktop {
                max-width: 600px;
            }

            @media @laptop {
                max-width: 440px;
            }

            @media @laptop--small {
                max-width: 312px;
            }
        }

        &-bottom {
            position: absolute;
            display: flex;
            align-items: center;
            left: 0;
            bottom: 36px;

            @media @desktop--big {
                bottom: 56px;
            }

            @media @laptop {
                bottom: 32px;
            }

            @media @laptop--small {
                bottom: 24px;
            }

            @media @phone {
                display: block;
                width: 100%;
            }
        }

        &-note {
            color: @color-black;

            &.isError {
                color: @color-red;
            }

            @media @phone {
                text-align: center;
            }
        }
    }
</style>

import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';
import {v4 as uuidv4} from 'uuid';

import user from './user';
import header from './header';
import dashboard from 'modules/dashboard/store';
import courses from 'modules/courses/store';
import profile from 'modules/profile/store';

import {DATA_LOADED_CHANGE, NOTIFICATIONS_VISIBILITY_TOGGLE} from './consts';

Vue.use(Vuex);

const state = {
    socket: {
        isConnected: false,
        message: '',
        reconnectError: false
    },
    promises: {},
    dataLoaded: true,
    mutationsCommited: 0,
    notificationsVisible: false
};

const mutations = {
    SOCKET_ONOPEN(state, event) {
        Vue.prototype.$socket = event.currentTarget;
        state.socket.isConnected = true;
    },
    SOCKET_ONCLOSE(state) {
        state.socket.isConnected = false;
    },
    SOCKET_ONERROR(state, event) {
        console.error(state, event);
    },
    // default handler called for all methods
    SOCKET_ONMESSAGE(state, message) {
        state.socket.message = message;
    },
    // mutations for reconnect methods
    SOCKET_RECONNECT(state, count) {
        console.info(state, count);     // eslint-disable-line
    },
    SOCKET_RECONNECT_ERROR(state) {
        state.socket.reconnectError = true;
    },
    [DATA_LOADED_CHANGE](state, payload = false) {
        state.dataLoaded = payload;

        if (!payload) state.mutationsCommited = 0;
    },
    [NOTIFICATIONS_VISIBILITY_TOGGLE](state, visibility) {
        // Change visibility to reverse value or set value from parameters
        if (visibility !== undefined) state.notificationsVisible = visibility;
        else state.notificationsVisible = !state.notificationsVisible;
    },
    SOCKET_SENT(state, {uuid, promise}) {
        state.promises[uuid] = promise;
    },
    SOCKET_RECEIVED(state, payload) {
        state.promises[payload.meta.uuid] && state.promises[payload.meta.uuid].resolve(payload);
        delete state.promises[payload.meta.uuid];
    }
};

const actions = {
    sendMessage: function (context, message) {
        const _meta = {
            'uuid': uuidv4()
        };

        let resolve;
        const promise = new Promise(function (_resolve) {
            resolve = _resolve;
        });
        promise.resolve = resolve;

        function send() {
            // return;
            if (context.state.socket.isConnected) {
                Vue.prototype.$socket.sendObj({...message, _meta});
                context.commit('SOCKET_SENT', {
                    uuid: _meta.uuid,
                    promise
                });
            } else {
                setTimeout(send, 100);
            }
        }

        send();

        return promise;
    },
    sendBinary: function (context, data) {
        function send() {
            // return;
            if (context.state.socket.isConnected) {
                Vue.prototype.$socket.send(data);
            } else {
                setTimeout(send, 100);
            }
        }

        send();
    }
};

let plugins = [];

if (process.env.isStaging) {
    plugins.push(createLogger());
}

export * from './consts';

export default new Vuex.Store({
    state,
    actions,
    mutations,
    modules: {
        user,
        header,
        courses,
        dashboard,
        profile
    },
    plugins
});

import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    quick: {},
    weeks: [],
    week: [],
    asyncEnrollments: []
};

export * from './consts';

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state
};

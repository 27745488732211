export default {
    data() {
        return {
            prevRoute: null
        };
    },

    computed: {
        hasHistory() {
            return this.prevRoute && !!this.prevRoute.name;
        }
    }
};

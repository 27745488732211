<template>
    <component class="PageLink s-text-label" :class="[`PageLink--${theme}`, mods.map(mod => `PageLink--${mod}`)]"
                 :to="{name: route, params: params}" @click="onClick" :is="route ? 'router-link': 'div'">
        <div class="PageLink-icon">
            <svg-view name="arrow"/>
        </div>
        <div>
            <slot/>
        </div>
    </component>
</template>

<script>
    export default {
        name: 'PageLink',

        props: {
            route: String,
            params: {
                type: Object,
                default: () => ({})
            },
            theme: {
                type: String,
                default: 'dark'
            },
            mods: {
                type: Array,
                default: () => []
            },
            back: {
                type: Boolean,
                default: false
            }
        },

        methods: {
            onClick(e) {
                if (!this.back) return;

                e.preventDefault();

                this.$router.back();
            }
        }
    };
</script>

<style lang="less">
    @import "~styles/const.less";

    .PageLink {
        display: flex;
        align-items: flex-start;
        color: @color-black;

        &-icon {
            display: flex;
            align-items: center;
            height: 28px;

            @media @desktop--big {
                height: 36px;
            }

            @media @laptop {
                height: 20px;
            }

            @media @laptop--small {
                height: 16px;
            }

            .isResizable & {
                @media @phone {
                    height: 20px;
                }
            }

            svg {
                width: 11px;
                height: 9px;
                margin-right: 19px;
                fill: @color-black;
                transform: rotate(180deg);

                @media @desktop--big {
                    width: 16px;
                    height: 13px;
                    margin-right: 20px;
                }

                @media @laptop {
                    width: 9px;
                    height: 8px;
                    margin-right: 10px;
                }

                @media @laptop--small {
                    width: 6px;
                    height: 6px;
                    margin-right: 7px;
                }

                @media @phone {
                    width: 9px;
                    height: 8px;
                    margin-right: 6px;
                }
            }
        }

        &--light {
            color: @color-primary;

            svg {
                fill: @color-primary;
            }
        }

        &--forward {
            flex-flow: row-reverse;

            .PageLink-icon {
                transform: rotate(180deg);
            }
        }
    }
</style>

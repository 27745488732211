import {
    LIST_FETCH,
    ITEM_FETCH,
    LESSON_FETCH,
    LESSON_CLEAR,
    WEBINAR_FETCH,
    WEBINAR_CLEAR,
    COURSE_STATUS,
    HOMEWORK_SEND,
    HOMEWORK_UPLOADING,
    HOMEWORK_UPDATE,
    HOMEWORK_ADD_FILE,
    HOMEWORK_CLEAR,
    LESSON_HOMEWORKS_LIST,
    LESSON_HOMEWORKS_FETCH,
    HOMEWORK_DOWNLOAD,
    HOMEWORKS_DOWNLOAD,
    DESIGN_LIBRARY_FETCH,
    HOMEWORK_COMMENT_SAVE,
    HOMEWORK_COMMENT_REMOVE,
    LESSON_HOMEWORKS_VIEWED,
    WORK_STATUSES,
    FILE_KINDS,
    LESSON_SESSION_ID,
    LESSON_SESSION_DATA,
    HOMEWORK_CHAT_SEND,
    HOMEWORK_CHAT_MESSAGES,
    VIEWED_GET
} from './consts';

import {OPTION_ALL} from 'front/constants/Constants';

import Vue from 'vue';
import {DateTime} from 'luxon';

import {formatRange, calculateProgress, mapPreview, checkFileTypeIsImage} from 'utils/utils';

const now = DateTime.local();

function getStatus(course) {
    let status;

    if (course.closedAt) {
        status = COURSE_STATUS.Past;
    } else if (course.beginAt <= now) {
        status = COURSE_STATUS.Active;
    } else {
        status = COURSE_STATUS.Future;
    }

    return status;
}

function mapAttachment(file, commentsViewed = false) {
    let kind = FILE_KINDS.other;
    let comments = file.comments;

    if (checkFileTypeIsImage(file.mimeType)) {
        kind = FILE_KINDS.image;
    } else if (file.mimeType.indexOf('video') === 0) {
        kind = FILE_KINDS.video;
    }

    if (commentsViewed) {
        comments = comments.map(comment => ({
            ...comment,
            isNew: false
        }));
    }

    return {
        ...file,
        kind,
        comments,
        // Duplicate id to frontId to make similar logic for new files & for loaded from backend
        // Make this id string, because ids generated on front are strings
        frontId: file.id.toString()
    };
}

function mapSubmission(submission, ind, commentsViewed = false) {
    const version = isNaN(ind) ? submission.version : ind + 1;
    const files = submission.files.map(file => mapAttachment(file, commentsViewed));
    let status = WORK_STATUSES.review;

    if (submission.checked) {
        if (submission.approved) {
            status = WORK_STATUSES.accepted;
        } else {
            status = WORK_STATUSES.revision;
        }
    }

    return {
        ...submission,
        status,
        files,
        version,
        updatedAt: submission.updatedAt && DateTime.fromISO(submission.updatedAt)
            .toFormat('dd.MM.yyyy • HH:mm'),
        sentAt: submission.sentAt && DateTime.fromISO(submission.sentAt)
            .toFormat('dd.MM.yyyy • HH:mm'),
        image: files.find(file => file.kind === FILE_KINDS.image)
    };
}

function mapModule(module) {
    const mapWeek = (week) => {
        const beginAt = week.beginAt && DateTime.fromISO(week.beginAt, {locale: 'ru', setZone: true});
        const endAt = week.endAt && DateTime.fromISO(week.endAt, {locale: 'ru', setZone: true});

        return {
            ...week,
            beginAt,
            endAt,
            isStarted: now >= beginAt,
            dates: formatRange(beginAt, endAt)
        };
    };

    const weeks = module.weeks.map(mapWeek)
        .sort((a, b) => a.beginAt > b.beginAt);
    const beginAt = weeks.length && weeks[0].beginAt || now;
    const endAt = weeks.length && weeks[weeks.length - 1].endAt || now;

    return {
        ...module,
        weeks,
        beginAt,
        endAt
    };
}

function mapCertificate(certificate, certificateUuid) {
    if (!certificate) return;
    const beginAt = certificate.course.beginAt && DateTime.fromISO(certificate.course.beginAt, {
        locale: 'ru', setZone: true
    });
    const endAt = certificate.course.endAt && DateTime.fromISO(certificate.course.endAt, {
        locale: 'ru', setZone: true
    });
    let beginFormat = 'd.MM.yyyy';
    let endFormat = 'd.MM.yyyy';

    if (beginAt) {
        if (endAt && beginAt.hasSame(endAt, 'year')) {
            if (endAt && beginAt.hasSame(endAt, 'month')) {
                beginFormat = 'd';
            } else {
                beginFormat = 'd.MM';
            }
        }
    }

    return {
        uuid: certificateUuid,
        completed: certificate.completed,
        course: {
            ...certificate.course,
            beginAt: beginAt && beginAt.toFormat(beginFormat),
            endAt: endAt && endAt.toFormat(endFormat),
            dates: formatRange(beginAt, endAt)
        }
    };
}

export default {
    [LIST_FETCH](state, payload) {
        Vue.set(state, 'courses', payload.data.map((course) => {
            const beginAt = course.beginAt && DateTime.fromISO(course.beginAt, {locale: 'ru', setZone: true});
            const endAt = course.endAt && DateTime.fromISO(course.endAt, {locale: 'ru', setZone: true});
            const progress = calculateProgress(beginAt, endAt);

            const asyncBeginAt = course.boughtAt && DateTime.fromISO(course.boughtAt, {locale: 'ru', setZone: true});
            const asyncEndAt = asyncBeginAt && asyncBeginAt.plus({months: course.durationInMonths * 2 + 6});

            let updatedCourse = {
                ...course,
                beginAt,
                endAt,
                progress,
                closedAt: course.closedAt && DateTime.fromISO(course.closedAt, {locale: 'ru', setZone: true}),
                asyncDates: formatRange(asyncBeginAt, asyncEndAt),
                dates: formatRange(beginAt, endAt),
                preview: mapPreview(course),
                isStudent: payload.isStudent
            };
            updatedCourse.status = getStatus(updatedCourse);

            return updatedCourse;
        }));
    },
    [ITEM_FETCH](state, payload) {
        const course = payload.data.course;
        const beginAt = course.beginAt && DateTime.fromISO(course.beginAt, {locale: 'ru', setZone: true});
        const endAt = course.endAt && DateTime.fromISO(course.endAt, {locale: 'ru', setZone: true});

        const asyncBeginAt = course.boughtAt && DateTime.fromISO(course.boughtAt, {locale: 'ru', setZone: true});
        const asyncEndAt = asyncBeginAt && asyncBeginAt.plus({ months: course.durationInMonths * 2 + 6 });

        const progress = calculateProgress(beginAt, endAt);
        const updatedCourse = {
            ...course,
            beginAt,
            endAt,
            progress,
            dates: formatRange(beginAt, endAt),
            asyncDates: formatRange(asyncBeginAt, asyncEndAt),
            preview: mapPreview(course),
            modules: payload.data.schedule && payload.data.schedule.map(mapModule) || [],
            certificate: mapCertificate(payload.data.certificate, payload.data.certificateUuid)
        };

        updatedCourse.status = getStatus(updatedCourse);

        Vue.set(state, 'course', updatedCourse);
    },
    [LESSON_FETCH](state, payload) {
        const weekBeginAt = payload.data.schedule.week && payload.data.schedule.week.beginAt &&
            DateTime.fromISO(payload.data.schedule.week.beginAt, {locale: 'ru', setZone: true});
        const weekEndAt = payload.data.schedule.week && payload.data.schedule.week.endAt &&
            DateTime.fromISO(payload.data.schedule.week.endAt, {locale: 'ru', setZone: true});

        Vue.set(state, 'lesson', {
            ...payload.data,
            schedule: {
                ...payload.data.schedule,
                enrollment: {
                    ...payload.data.schedule.enrollment,
                    preview: mapPreview(payload.data.schedule.enrollment)
                },
                week: {
                    ...payload.data.schedule.week,
                    beginAt: weekBeginAt,
                    endAt: weekEndAt,
                    dates: formatRange(weekBeginAt, weekEndAt)

                }
            },
            files: payload.data.files && payload.data.files.map((file) => {
                return {
                    ...file,
                    name: file.file && decodeURIComponent(file.file.split('/')
                        .pop())
                };
            }),
            homework: {
                ...payload.data.homework,
                completeAt: payload.data.homework && payload.data.homework.completeAt
                    && DateTime.fromISO(payload.data.homework.completeAt)
                    || null,
                checkAt: payload.data.homework && payload.data.homework.checkAt
                    && DateTime.fromISO(payload.data.homework.checkAt)
                    || null,
                works: [],
                files: payload.data.homework &&
                    payload.data.homework.files &&
                    payload.data.homework.files.map((file) => {
                        return {
                            ...file,
                            name: file.file && decodeURIComponent(file.file.split('/')
                                .pop())
                        };
                    }),
                downloads: payload.data.homework && payload.data.homework.downloads &&
                    payload.data.homework.downloads.reduce((obj, download) => {
                        obj[download.status || OPTION_ALL] = download.file;

                        return obj;
                    }, {}) || {}
            }
        });
    },
    [LESSON_CLEAR](state) {
        const lesson = {
            schedule: {
                enrollment: {}
            },
            homework: {
                works: []
            }
        };
        Vue.set(state, 'lesson', lesson);
    },
    [HOMEWORK_SEND](state, payload) {
        const error = payload.error || '';
        state.uploading.error = error;
        state.uploading.created = !error;
        state.uploading.id = payload.data && payload.data.submissionId || undefined;
        if (!state.uploading.files.length) state.uploading.loading = false;
    },
    [HOMEWORK_UPLOADING](state, payload) {
        // If file was uploaded
        if (payload.attachment) {
            const index = state.uploading.files.findIndex((a) => a.uuid === payload.attachment.uuid);

            // state.uploading.files[index] = {
            //     ...state.uploading.files[index],
            //     ...payload.attachment
            // };

            Vue.set(state.uploading.files, index, {
                ...state.uploading.files[index],
                ...payload.attachment
            });

            state.uploading.loading = state.uploading.uploading =
                !state.uploading.files.reduce((res, f) => !!f.file && res, true);

            state.uploading.files[index].promise.resolve({...payload});

            if (index === state.uploading.files.length - 1 && !!state.uploading.promise) {
                setTimeout(() => {
                    state.uploading.promise.resolve();
                    delete state.uploading.promise;
                }, 100);
            }

            return;
        }

        if (payload.error) payload.loading = false;

        Vue.set(state, 'uploading', {
            ...state.uploading,
            ...payload
        });
    },
    [LESSON_HOMEWORKS_LIST](state, payload) {
        const works = payload.data.map((homework) => {
            let status = WORK_STATUSES.unuploaded;

            if (homework.latest) {
                if (homework.latest.checked) {
                    if (homework.latest.approved) {
                        status = WORK_STATUSES.accepted;
                    } else {
                        status = WORK_STATUSES.revision;
                    }
                } else {
                    status = WORK_STATUSES.review;
                }
                homework.updatedAt = DateTime.fromISO(homework.latest.updatedAt, {locale: 'ru', setZone: true});
            } else {
                homework.updatedAt = null;
            }

            const cover = homework.latest && homework.latest.cover || {};
            const id = homework.latest && homework.latest.id;

            return {
                ...homework,
                latest: {
                    ...homework.latest,
                    cover: {
                        preview: cover.preview ? cover.preview + `?${id}` : '',
                        preview2x: cover.preview2x ? cover.preview2x + `?${id}` : ''
                    }
                },
                userpic: homework.userpic ? homework.userpic + '?' + now.valueOf() : '',
                status
            };
        });

        works.sort((a, b) => {
            if (a.updatedAt < b.updatedAt) {
                return 1;
            } else if (a.updatedAt > b.updatedAt) {
                return -1;
            } else {
                if (a.name < b.name) {
                    return -1;
                } else if (a.name > b.name) {
                    return 1;
                }
            }

            return 0;
        });

        Vue.set(state, 'homeworks', works);
    },
    [LESSON_HOMEWORKS_FETCH](state, payload) {
        Vue.set(state, 'homework', {
            user: payload.data.user,
            teacher: payload.data.teacher,
            // Submissions come from server ordered by date asc, in editor we need them in descending order
            versions: payload.data.submissions.map((submission, ind) => mapSubmission(submission, ind))
                .reverse()
        });
    },
    [HOMEWORK_UPDATE](state, payload) {
        state.homework.versions = state.homework.versions.map((submission) => {
            if (submission.id !== payload.data.id) {
                return submission;
            }

            return mapSubmission({
                ...payload.data,
                version: submission.version
            });
        });

        state.uploading.loading = false;
        state.uploading.created = true;
    },
    [HOMEWORK_ADD_FILE](state, payload) {
        const lastVersionFiles = state.homework.versions[0].files;
        lastVersionFiles.push(payload.attachment);
    },
    [HOMEWORK_CLEAR](state) {
        const homework = {
            files: []
        };
        Vue.set(state, 'homework', homework);
        Vue.set(state, 'comments', []);
    },
    [WEBINAR_FETCH](state, payload) {
        const weekBeginAt = payload.data.schedule.week.beginAt &&
            DateTime.fromISO(payload.data.schedule.week.beginAt, {locale: 'ru', setZone: true});
        const weekEndAt = payload.data.schedule.week.endAt &&
            DateTime.fromISO(payload.data.schedule.week.endAt, {locale: 'ru', setZone: true});

        Vue.set(state, 'webinar', {
            ...payload.data,
            schedule: {
                ...payload.data.schedule,
                week: {
                    ...payload.data.schedule.week,
                    dates: formatRange(weekBeginAt, weekEndAt)

                }
            },
            files: payload.data.files && payload.data.files.map((file) => {
                return {
                    ...file,
                    name: file.file && decodeURIComponent(file.file.split('/')
                        .pop())
                };
            }),
            datetime: payload.data.datetime
                && DateTime.fromISO(payload.data.datetime, {locale: 'ru', setZone: true})
                    .toFormat('dd MMMM, HH:mm')
                || null
        });
    },
    [HOMEWORK_DOWNLOAD](state, payload) {
        state.homework.versions[state.homework.versions.length - 1].download = payload && payload !== true
            ? payload.data.url
            : payload;

        if (payload && payload !== true) {
            window.open(payload.data.url, 'Download');
        }
    },
    [HOMEWORKS_DOWNLOAD](state, {status, data}) {
        Vue.set(
            state.lesson.homework.downloads,
            status || data.status || OPTION_ALL,
            data !== true
                ? data.url
                : data
        );

        if (data && data !== true) {
            window.open(data.url, 'Download');
        }
    },
    [DESIGN_LIBRARY_FETCH](state, payload) {
        Vue.set(state, 'courses', payload.data.map((course) => {
            let updatedCourse = {
                ...course,
                preview: mapPreview(course)
            };

            return updatedCourse;
        }));
    },
    [WEBINAR_CLEAR](state) {
        const webinar = {};
        Vue.set(state, 'webinar', webinar);
    },
    [HOMEWORK_COMMENT_SAVE](state, payload) {
        const fileInd = state.homework.versions[payload.version].files
            .findIndex(file => file.frontId === payload.fileId);
        const comments = (state.homework.versions[payload.version].files[fileInd].comments || []);
        const commentInd = comments.findIndex(comment => comment.number === payload.comment.number);

        // If comment is new
        if (commentInd === -1) {
            comments.push(payload.comment);
        } else {
            comments.splice(commentInd, 1, payload.comment);
        }

        Vue.set(state.homework.versions[payload.version].files[fileInd].comments, comments);
    },

    [HOMEWORK_COMMENT_REMOVE](state, payload) {
        const fileInd = state.homework.versions[payload.version].files
            .findIndex(file => file.frontId === payload.fileId);
        const comments = (state.homework.versions[payload.version].files[fileInd].comments || []);
        const commentInd = comments.findIndex(comment => comment.number === payload.number);

        if (commentInd !== -1) comments.splice(commentInd, 1);

        Vue.set(state.homework.versions[payload.version].files[fileInd].comments, comments);
    },
    [LESSON_HOMEWORKS_VIEWED](state, payload) {
        const versionInd = state.homework.versions.findIndex(version => version.id === payload.id);
        state.homework.versions.splice(versionInd, 1,
            mapSubmission(state.homework.versions[versionInd], versionInd, true));

        // Vue.set(state.homework, 'versions', versions);
    },
    [LESSON_SESSION_ID](state, payload) {
        Vue.set(state.lessonSession, 'id', payload.id);
    },
    [LESSON_SESSION_DATA]() {
    },
    [HOMEWORK_CHAT_SEND](state, payload) {
        if (!payload.data.file || !state.uploading.files[0]) {
            return;
        }

        state.uploading.files[0].resolve({...payload});
    },
    [HOMEWORK_CHAT_MESSAGES](state, payload) {
        const comments = (payload.data || []).reverse();
        Vue.set(state, 'comments', comments);
    },
    [VIEWED_GET](state, payload) {
        console.log(payload);
    }
};

// import axios from 'axios';

export const NAMESPACE = 'header';

export const HEADER_THEME_CHANGE = 'HEADER/THEME/CHANGE';

export const THEME_DARK = 'dark';
export const THEME_LIGHT = 'light';

const state = {
    theme: THEME_LIGHT
};

const getters = {};

const actions = {};

const mutations = {
    [HEADER_THEME_CHANGE](state, payload = THEME_LIGHT) {
        state.theme = payload;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

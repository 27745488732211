<template>
    <div class="Input" :class="mods.map(mod => `Input--${mod}`)">
        <div class="Input-content">
            <label class="s-text-label">{{ label }}</label>
            <input class="s-text-common" v-model="inputValue" :placeholder="placeholder" :type="inputType"
                   :disabled="disabled" @keypress.enter.prevent>
            <button class="Input-button Input-button--password" @click.prevent="toggleInputVisibility"
                    v-if="type === INPUT_TYPES.password && !disabled">
                <svg-view name="hidden" v-if="inputType === INPUT_TYPES.password"/>
                <svg-view name="visible" v-else/>
            </button>
            <component class="Input-button s-text-caption" v-if="buttonText" @click="$emit('button-click')"
                       :is="to ? 'router-link' : 'button'" :to="to">
                {{ buttonText }}
            </component>
        </div>
    </div>
</template>

<script>
    import {INPUT_TYPES} from 'front/constants/Constants';

    export default {
        name: 'InputView',

        props: {
            mods: {
                type: Array,
                default: () => []
            },
            label: {
                type: String,
                required: true
            },
            placeholder: {
                type: String,
                default: ''
            },
            value: {
                type: String,
                default: ''
            },
            type: {
                type: String,
                default: 'text'
            },
            disabled: {
                type: Boolean,
                default: false
            },
            buttonText: String,
            to: Object
        },

        computed: {
            inputValue: {
                get() {
                    return this.value;
                },
                set(val) {
                    this.$emit('input', val);
                }
            }
        },

        data() {
            return {
                INPUT_TYPES,
                inputType: 'text'
            }
        },

        mounted() {
            this.inputType = this.type
        },

        methods: {
            toggleInputVisibility() {
                this.inputType = this.inputType === INPUT_TYPES.password ? INPUT_TYPES.text : INPUT_TYPES.password;
            }
        }
    };
</script>

<style lang="less" scoped>
    @import "~styles/const.less";

    .Input {
        padding-bottom: 28px;
        border-bottom: 1px solid @color-gray--light;
        text-align: left;

        @media @desktop--big {
            padding-bottom: 40px;
        }

        @media @laptop {
            padding-bottom: 20px;
        }

        @media @laptop--small {
            padding-bottom: 16px;
        }

        .isResizable & {
            @media @phone {
                padding-bottom: 20px;
            }
        }

        &-content {
            position: relative;
            display: flex;

            .isResizable & {
                @media @phone {
                    flex-flow: column;
                }
            }
        }

        label {
            width: 150px;
            margin-right: 5px;
            color: @color-gray--dark;

            @media @desktop--big {
                width: 205px;
            }

            @media @laptop {
                width: 106px;
            }

            @media @laptop--small {
                width: 73px;
            }

            .isResizable & {
                @media @phone {
                    margin-bottom: 4px;
                    color: @color-black;
                }
            }
        }

        input {
            flex: 1;
            color: @color-black;

            &::placeholder {
                color: @color-gray--middle;
                opacity: 1;
            }
        }

        &-button {
            position: absolute;
            top: 50%;
            right: 0;
            margin: auto 0;
            transform: translateY(-50%);

            &:not(&--password) {
                padding: 10px 24px;
                border: 1px solid @color-gray--light;
                border-radius: 50px;
                color: @color-black;

                @media @phone {
                    padding: 8px 20px 8px 16px;
                    border-radius: 40px;
                }

                @media @desktop--big {
                    padding: 16px 32px;
                    border-radius: 64px;
                }

                @media @laptop {
                    padding: 8px 16px;
                    border-radius: 40px;
                }

                @media @laptop--small {
                    padding: 6px 12px;
                    border-radius: 28px;
                }

                @media @phone {
                    padding: 8px 20px;
                }
            }

            &--password {
                width: 24px;
                height: 24px;

                @media @desktop--big {
                    width: 32px;
                    height: 32px;
                }

                @media @laptop {
                    width: 18px;
                    height: 18px;
                }

                @media @laptop--small {
                    width: 12px;
                    height: 12px;
                }

                .isResizable & {
                    @media @phone {
                        width: 16px;
                        height: 16px;
                        top: initial;
                        bottom: 4px;
                        transform: none;
                    }
                }

                svg {
                    fill: @color-black;
                }
            }
        }

        &--big {
            label {
                width: 295px;

                @media @desktop--big {
                    width: 205px;
                }

                @media @laptop {
                    width: 225px;
                }

                @media @laptop--small {
                    width: 139px;
                }

                .isResizable & {
                    @media @phone {
                        width: 100%;
                    }
                }
            }
        }

        &--light {
            border-bottom-color: @color-gray--dark;

            input {
                color: @color-primary;

                &::placeholder {
                    color: @color-gray--dark;
                }
            }
        }
    }
</style>

<template>
    <div class="isResizable">
        <router-view/>
    </div>
</template>

<script>
    export default {
        name: 'LoginPageWrapper'
    };
</script>

<style lang="less">
    @import "~styles/const.less";

    body {
        background: @color-gray;
        overflow: auto;

        @media @bigger-then-phone {
            min-width: 1024px;
        }
    }
</style>

import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    profile: {
        social: [],
        notifications: []
    },
    uploading: undefined
};

export * from './consts';

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state
};

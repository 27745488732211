import {DateTime} from 'luxon';
import Vue from 'vue';
import {calculateProgress, mapPreview} from 'utils/utils';
import Cookies from 'js-cookie';
import {NOTIFICATION_TITLES, NOTIFICATION_ROUTES, NOTIFICATION_DATETIME_FORMATS, TIME_DIFF_NAMES} from './consts';
import {WORK_STATUSES, WORK_STATUSES_DISPLAY} from 'front/modules/courses/store';

export const NAMESPACE = 'user';

export const USER_FETCH = 'USER/FETCH';
export const USER_USERPIC = 'USER/USERPIC';
export const USER_CHECK = 'USER/CHECK';
export const USER_LOGOUT = 'USER/LOGOUT';
export const USER_SET_LAST_LESSON = 'USER/LASTLESSON/SET';
export const USER_NOTIFY = 'USER/NOTIFY';
export const USER_NOTIFICATIONS_SHOWN = 'USER/NOTIFICATIONS/SHOWN';
export const USER_NOTIFICATIONS_FETCH = 'USER/NOTIFICATIONS/FETCH';

export const USER_ROLE_STUDENT = 's';
export const USER_ROLE_TEACHER = 't';
export const USER_ROLE_COORDINATOR = 'c';
export const USER_ROLE_ADMIN = 'a';

export const USER_CHECK_INTERVAL = process.env.isStaging ? 10000 : 60000;

export const USER_NOTIFICATIONS_NOW_PERIOD = 10000;
export const USER_NOTIFICATIONS_COUNT = 10;

const state = {
    data: {
        id: null,
        percentages: [74, 4],
        courses: [],
        social: [],
        userpic: null
    },
    notifications: [],
    allNotifications: []
};

function pluralize(count, words) {
    var cases = [2, 0, 1, 1, 1, 2];

    return words[(count % 100 > 4 && count % 100 < 20) ? 2 : cases[Math.min(count % 10, 5)]];
}

function getNotificationTitle(notification, user) {
    let title = NOTIFICATION_TITLES[notification.kind];
    if (typeof title !== 'string') {
        title = user && title[user.role] || '';
    }

    return title
        .replace('$name', notification.name)
        .replace('$author', notification.author)
        .replace('$count_new', '' + notification.worksCount + ' ' + pluralize(notification.worksCount, [
            'новая работа',
            'новые работы',
            'новых работ'
        ]))
        .replace('$count', '' + notification.worksCount + ' ' + pluralize(notification.worksCount, [
            'работа',
            'работы',
            'работ'
        ]))
        .replace('$awaiting', pluralize(notification.worksCount, [
            'ждет',
            'ждут',
            'ждут'
        ]))
        .replace('$datetime', notification.datetime);
}

export function getTimeDelta(isoTime, now) {
    const time = typeof isoTime === 'string' ? DateTime.fromISO(isoTime) : isoTime;
    const _now = now || DateTime.local();
    const diffUnits = ['weeks', 'days', 'hours', 'minutes'];
    const diff = _now.diff(time, diffUnits)
        .toObject();

    for (const unit of diffUnits) {
        if (diff[unit] >= 1) return Math.floor(diff[unit]) + TIME_DIFF_NAMES[unit];
    }

    return Math.floor(diff.hours);
}

function mapNotification(notification, user) {
    const notificationData = {
        ...notification,
        ...notification.enrollment,
        ...notification.webinar,
        ...notification.lesson,
        ...(notification.homework && notification.homework.lesson || {}),
        ...notification.homework,
        notificationId: notification.id
    };

    if (notificationData.datetime && NOTIFICATION_DATETIME_FORMATS[notificationData.kind]) {
        notificationData.datetime = DateTime.fromISO(notificationData.datetime, {locale: 'ru'})
            .toFormat(NOTIFICATION_DATETIME_FORMATS[notificationData.kind]);
    }

    return {
        ...notificationData,
        time: notificationData.time && DateTime.fromISO(notificationData.time) || undefined,
        title: getNotificationTitle(notificationData, user),
        to: {
            name: NOTIFICATION_ROUTES[notificationData.kind],
            params: {
                id: notificationData.id,
                courseId: notificationData.enrollment && notificationData.enrollment.id,
                slug: notificationData.enrollment && notificationData.enrollment.slug,
                lessonId: notificationData.lesson && notificationData.lesson.id,
                status: WORK_STATUSES_DISPLAY[WORK_STATUSES.review].field
            }
        }
    };
}


const getters = {
    isTeacher(state) {
        return state.data.role === USER_ROLE_TEACHER ||
            state.data.role === USER_ROLE_COORDINATOR ||
            state.data.role === USER_ROLE_ADMIN;
    },
    isCoordinator(state) {
        return state.data.role === USER_ROLE_COORDINATOR ||
            state.data.role === USER_ROLE_ADMIN;
    },
    currentCourse(state) {
        const courses = state.data.courses.sort((a, b) => {
            if (a.beginAt < b.beginAt) return 1;

            if (a.beginAt > b.beginAt) return -1;

            return 0;
        });

        return courses && courses[0];
    },
    allNotifications(state) {
        return state.allNotifications;
    },
    lastNotifications(state) {
        return state.notifications.slice(0, USER_NOTIFICATIONS_COUNT);
    },
    newNotifications(_, getters) {
        return getters.lastNotifications.filter((notification) => !!notification.isNew);
    },
    newNotificationsCount(_, getters) {
        return getters.newNotifications.length;
    }
};

const actions = {
    [USER_FETCH](context) {
        return context.dispatch('sendMessage', {
            method: `${NAMESPACE}/${USER_FETCH}`,
            data: {
                sessionid: Cookies.get('sessionid')
            }
        }, {root: true});
    },
    [USER_NOTIFICATIONS_FETCH](context) {
        return context.dispatch('sendMessage', {
            method: `${NAMESPACE}/${USER_NOTIFICATIONS_FETCH}`,
            data: {
                sessionid: Cookies.get('sessionid')
            }
        }, {root: true});
    },
    [USER_CHECK](context) {
        return context.dispatch('sendMessage', {
            method: `${NAMESPACE}/${USER_CHECK}`,
            data: {
                sessionid: Cookies.get('sessionid')
            }
        }, {root: true});
    },
    [USER_SET_LAST_LESSON](context, data) {
        return context.dispatch('sendMessage', {
            method: `${NAMESPACE}/${USER_SET_LAST_LESSON}`,
            data
        }, {root: true});
    },
    [USER_NOTIFICATIONS_SHOWN](context, data) {
        context.commit(USER_NOTIFICATIONS_SHOWN);

        return context.dispatch('sendMessage', {
            method: `${NAMESPACE}/${USER_NOTIFICATIONS_SHOWN}`,
            data
        }, {root: true});
    }
};

/* eslint-disable */

// TODO: Remove, fake data
const notifications = [
    {
        kind: 1,
        time: '2021-02-25T10:00:00Z',
        isNew: true,
        enrollment: {
            id: 1,
            slug: 'graphdesign',
            name: 'Графический дизайн'
        }
    },
    {
        kind: 2,
        time: '2021-02-24T10:00:00Z',
        enrollment: {
            id: 1,
            slug: 'graphdesign',
            name: 'Графический дизайн'
        }
    },
    {
        kind: 3,
        time: '2021-02-15T10:00:00Z',
        webinar: {
            id: 1,
            name: 'Итоговый просмотр',
            enrollment: {
                id: 1,
                slug: 'graphdesign'
            },
            datetime: '2021-02-28T10:00:00Z'
        }
    },
    {
        kind: 4,
        time: '2021-01-24T10:00:00Z',
        webinar: {
            id: 1,
            name: 'Итоговый просмотр',
            enrollment: {
                id: 1,
                slug: 'graphdesign'
            }
        }
    },
    {
        kind: 5,
        time: '2021-01-24T10:00:00Z',
        webinar: {
            id: 1,
            name: 'Итоговый просмотр',
            enrollment: {
                id: 1,
                slug: 'graphdesign'
            },
            datetime: '2021-02-28T10:00:00Z'
        }
    },
    {
        kind: 6,
        time: '2021-01-24T10:00:00Z',
        homework: {
            id: 1,
            lesson: {
                id: 1,
                enrollment: {
                    id: 1,
                    slug: 'graphdesign'
                }
            }
        }
    },
    {
        kind: 7,
        time: '2021-01-24T10:00:00Z',
        lesson: {
            id: 1,
            name: 'Свет и цвет',
            enrollment: {
                id: 1,
                slug: 'graphdesign'
            }
        }
    },
    {
        kind: 8,
        time: '2021-01-24T10:00:00Z',
        enrollment: {
            id: 1,
            slug: 'graphdesign',
            name: 'Графический дизайн'
        }
    },
    {
        kind: 9,
        time: '2021-01-24T10:00:00Z',
        lesson: {
            id: 1,
            name: 'Свет и цвет',
            enrollment: {
                id: 1,
                slug: 'graphdesign'
            },
            worksCount: 5
        }
    },
    {
        kind: 10,
        time: '2021-01-24T10:00:00Z',
        lesson: {
            id: 1,
            name: 'Свет и цвет',
            enrollment: {
                id: 1,
                slug: 'graphdesign'
            }
        }
    },
    {
        kind: 11,
        time: '2021-01-24T10:00:00Z',
        lesson: {
            id: 1,
            name: 'Свет и цвет',
            enrollment: {
                id: 1,
                slug: 'graphdesign'
            },
            worksCount: 5
        }
    }
];

/* eslint-enable */

const mutations = {
    [USER_FETCH](state, payload) {
        const ts = Date.now();
        Vue.set(state, 'data', {
            ...state.data,
            ...payload.data,
            isTeacher: getters.isTeacher({data: payload}),
            userpic: payload.data.userpic && Object.keys(payload.data.userpic)
                .reduce((obj, key) => {
                    obj[key] = payload.data.userpic[key] + '?' + ts;

                    return obj;
                }, {}) || undefined,
            courses: payload.data.courses.map((course) => {
                const beginAt = course.beginAt && DateTime.fromISO(course.beginAt, {locale: 'ru', setZone: true});
                const endAt = course.endAt && DateTime.fromISO(course.endAt, {locale: 'ru', setZone: true});
                const progress = calculateProgress(beginAt, endAt);

                return {
                    ...course,
                    beginAt,
                    endAt,
                    progress,
                    preview: mapPreview(course)
                };
            }),
            timestamp: ts
        });
        // state.notifications = notifications.map(mapNotification);

        const _mapNotification = (n) => mapNotification(n, payload.data);

        Vue.set(state, 'notifications', payload.data.notifications.map(_mapNotification));
    },
    [USER_NOTIFICATIONS_FETCH](state, payload) {
        const _mapNotification = (n) => mapNotification(n, payload.data);

        Vue.set(state, 'allNotifications', payload.data.notifications.map(_mapNotification));
    },
    [USER_NOTIFY](state, payload) {
        state.notifications.unshift({
            ...mapNotification(payload.data, state.data),
            isNew: true
        });
    },
    [USER_NOTIFICATIONS_SHOWN](state) {
        state.notifications = state.notifications.map(notification => ({
            ...notification,
            isNew: false
        }));
    },

    [USER_CHECK]() {
        // setTimeout(() => {
        //     this.dispatch(`${NAMESPACE}/${USER_CHECK}`);
        // }, USER_CHECK_INTERVAL);
    },
    [USER_LOGOUT]() {
        window.location = Urls['lms:logout']();
    },
    [USER_USERPIC](state, userpic) {
        const ts = Date.now();
        Vue.set(state.data, 'userpic', userpic && Object.keys(userpic)
            .reduce((obj, key) => {
                obj[key] = userpic[key] + '?' + ts;

                return obj;
            }, {}) || undefined);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

import Vue from 'vue';
import VueRouter from 'vue-router';

import { LoginPage, RecoveryPage } from './modules/login';


import store from './store';
import Urls from 'django_js_front/reverse.js';

Vue.use(VueRouter);

window.appStore = store;

const routes = [
    {
        path: Urls['lms:login'](),
        name: 'login',
        component: LoginPage,
        props: true, pathToRegexpOptions: {strict: true}
    },
    {
        path: Urls['lms:recovery'](),
        name: 'recovery',
        component: RecoveryPage,
        props: true, pathToRegexpOptions: {strict: true}
    },
    {
        path: Urls['lms:reset'](':token([\\w\\-]+)'),
        name: 'reset',
        component: RecoveryPage,
        props: true, pathToRegexpOptions: {strict: true}
    }
];

const router = new VueRouter({
    mode: 'history',
    routes: routes,
    scrollBehavior(to, from, savedPosition) {
        return savedPosition || {x: 0, y: 0};
    }
});
window.router = router;

router.afterEach(mixins.DocumentTitleMixin__updateTitle);

export {router, routes};

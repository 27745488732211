<template>
    <svg :class="['svg--'+name]">
        <use :xlink:href="static('svg/front/sprite.symbol.svg#'+name)"></use>
    </svg>
</template>

<script>
export default {
    name: "SvgView",
    props: {
        name: String
    }
};
</script>

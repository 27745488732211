import {
    LESSON_KINDS
} from 'modules/courses/store';


import {DateTime, Interval} from 'luxon';

import {formatRange} from 'utils/utils';

export default {
    weeksSlider(state) {
        const enrollments = state.weeks.map(week => week.enrollmentId)
            .filter((value, index, self) => self.indexOf(value) === index);

        // single enrollment
        if (enrollments.length <= 1) {
            return state.weeks;
        }

        const weeksRanges = state.weeks.map(week => Interval.fromDateTimes(week.beginAt, week.endAt));

        const merged = Interval.merge(weeksRanges).reduce((obj, val) => {
            return obj.union(val);
        }, weeksRanges[0]);

        return Interval.fromDateTimes(
            merged.start.startOf('week'),
            merged.end.endOf('week')
        ).splitBy({week: 1})
            .map((week) => ({
                beginAt: week.start,
                endAt: week.end.minus({days: 1})
            }));
    },
    weeksList(_state, getters) {
        function formatDates(beginAt, endAt) {
            let dates;

            if (+beginAt === +endAt) dates = endAt.toFormat('dd.LL');
            else {
                dates = beginAt && endAt
                    ? beginAt.toFormat('dd.LL') + ' - ' + endAt.toFormat('dd.LL')
                    : undefined;
            }

            return dates;
        }

        const now = DateTime.local();

        return getters.weeksSlider.map((week) => ({
            ...week,
            isStarted: week.beginAt && now >= week.beginAt,
            datesShort: formatDates(week.beginAt, week.endAt),
            dates: week.beginAt && week.endAt
                ? formatRange(week.beginAt, week.endAt)
                : undefined
        }));
    },
    quickAccess(state) {
        let quick = [];
        if (state.quick.lesson) {
            quick.push({
                ...state.quick.lesson,
                type: LESSON_KINDS.lesson
            });
        }
        if (state.quick.webinar) {
            quick.push({
                ...state.quick.webinar,
                type: LESSON_KINDS.webinar
            });
        }

        return quick;
    }
};

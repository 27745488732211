<template>
    <div class="Login">
        <div class="Login-background">
            <picture>
                <source :srcset="static('img/front/login-bg-mobile.png')" media="(max-width: 767px)">
                <img :src="static('img/front/login-bg.png')">
            </picture>
        </div>
        <div class="Login-logo">
            <a href="https://bangbangeducation.ru">
                <logo/>
            </a>
        </div>
        <div class="Login-content">
            <div class="Login-info">
                <div class="Login-title s-text-title">Вход в личный кабинет</div>
                <div class="Login-description s-text-caption">
                    Введите вашу почту и пароль от сайта bangbangeducation.ru
                </div>
            </div>
            <form class="Login-form s-form" @submit.prevent="onSubmit" @keypress.enter="submitEnabled && onSubmit()">
                <input-view label="Почта" placeholder="example@gmail.com" class="Login-input"
                            v-model="form.email"/>
                <input-view label="Пароль" placeholder="Введите пароль" class="Login-input"
                            v-model="form.password" type="password"/>
                <div class="Login-additional s-text-caption">
                    <div class="Login-error" v-if="hasError">{{ error }}</div>
                    <router-link class="Login-passwordReset" :to="{name: 'recovery'}">
                        Восстановить пароль
                    </router-link>
<!--                    <a class="Login-passwordReset" href="https://bangbangeducation.ru/password/reset" target="_blank">-->
<!--                        Восстановить пароль-->
<!--                    </a>-->
                </div>
                <button class="Login-submit s-button--primary s-text-caption" type="submit" :disabled="!submitEnabled">
                    Войти
                </button>
                <!--                Disabled until UNTI support is ready for production -->
<!--                <a href="/sign-in/2035" class="Login-unti s-text-caption">-->
<!--                  <span>Войти с помощью</span>-->
<!--                  <img :src="static('img/front/2035.svg')">-->
<!--                </a>-->
<!--                <div class="Login-social">-->
<!--                    <button>-->
<!--                        <svg-view name="facebook" class="s-button-icon"/>-->
<!--                    </button>-->
<!--                    <button>-->
<!--                        <svg-view name="vk" class="s-button-icon"/>-->
<!--                    </button>-->
<!--                    <button>-->
<!--                        <svg-view name="twitter" class="s-button-icon"/>-->
<!--                    </button>-->
<!--                </div>-->
            </form>
        </div>
        <div class="Login-footer s-text-caption">
            <div class="Login-footerContent">
                <a href="tel:+7(495)668-37-03">+7 (495) 668-37-03</a>
                <a href="mailto:info@bangbangeducation.ru">info@bangbangeducation.ru</a>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import get from 'lodash/get';

    import Logo from 'front/components/Logo.vue';
    import InputView from 'front/components/InputView.vue';

    export default {
        name: 'LoginPage',

        mixins: [mixins.DocumentTitleMixin],

        components: {
            Logo,
            InputView
        },

        data: function () {
            return {
                form: {
                    email: '',
                    password: ''
                },
                submitEnabled: false,
                error: '',
                hasError: false
            };
        },

        computed: {
            documentTitle() {
                return this.makeDocumentTitle('Вход');
            }
        },

        watch: {
            form: {
                handler() {
                    this.onChange();
                },
                deep: true
            }
        },

        methods: {
            getParameterByName(name) {
                const match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);

                return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
            },
            onChange() {
                this.submitEnabled = !!this.form.email && !!this.form.password;
                this.hasError = false;
            },
            onSubmit() {
                this.submitEnabled = false;

                axios.post(Urls['lms:api:login'](), {...this.form})
                    .then((response) => {
                        window.location = this.getParameterByName('return') || '/';
                    })
                    .catch((error) => {
                        this.error = get(error, 'response.data.error') || 'Почта или пароль введены неверно';
                        this.hasError = true;
                        this.submitEnabled = true;
                    });
            }
        }
    };
</script>

<style scoped lang="less">
    @import "~styles/const.less";

    .Login {
        position: relative;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;
        min-height: 800px;

        @media @desktop--big {
            min-height: 1000px;
        }

        @media @laptop {
            min-height: 600px;
        }

        @media @laptop--small {
            min-height: 450px;
        }

        @media @phone {
            min-height: 718px;
            padding: 76px 0 95px;
        }

        &-background {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;

            @media @phone {
                position: fixed;
                width: 100vw;
                height: 100vh;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        &-logo {
            position: absolute;
            left: 40px;
            top: 16px;

            a {
                display: inline-block;
            }

            @media @desktop--big {
                top: 24px;
            }

            @media @laptop {
                top: 12px;
            }

            @media @laptop--small {
                left: 28px;
                top: 8px;
            }

            @media @phone {
                left: 24px;
            }
        }

        &-content {
            position: relative;
            width: 680px;
            text-align: center;

            @media @desktop--big {
                width: 920px;
            }

            @media @laptop {
                width: 504px;
            }

            @media @laptop--small {
                width: 360px;
            }

            @media @phone {
                width: auto;
            }
        }

        &-info {
            margin-bottom: 40px;

            @media @desktop--big {
                margin-bottom: 48px;
            }
            @media @laptop {
                margin-bottom: 32px;
            }

            @media @laptop--small {
                margin-bottom: 24px;
            }

            @media @phone {
                margin: 0 24px 24px;
            }
        }

        &-title {
            margin-bottom: 8px;

            @media @desktop--big {
                margin-bottom: 16px;
            }

            @media @laptop--small, @phone {
                margin-bottom: 4px;
            }
        }

        &-form {
            display: flex;
            flex-flow: column;
            padding: 40px;
            background: @color-primary;
            border-radius: 8px;

            @media @desktop--big {
                padding: 56px;
                border-radius: 10px;
            }

            @media @laptop {
                padding: 32px;
                border-radius: 6px;
            }

            @media @laptop--small {
                padding: 24px;
                border-radius: 4px;
            }

            @media @phone {
                padding: 20px;
                margin: 0 4px;
            }
        }

        &-additional {
            display: flex;
            justify-content: flex-end;
            margin-top: 16px;

            @media @desktop--big {
                margin-top: 20px;
            }

            @media @laptop {
                margin-top: 12px;
            }

            @media @laptop--small {
                margin-top: 8px;
            }

            @media @phone {
                height: 68px;
                flex-flow: column-reverse;
                justify-content: space-between;
            }
        }

        &-error {
            @media @bigger-then-phone {
                justify-self: flex-start;
                margin-right: auto;
            }
            color: @color-red;
        }

        &-passwordReset {
            color: @color-gray--dark;
            text-align: right;

            @media @phone {
                justify-self: flex-start;
                margin-bottom: auto;
            }
        }

        &-submit {
            width: 100%;
            padding: 10px;
            border-radius: 70px;
            margin: 48px 0 23px;

            @media @desktop--big {
                padding: 18px;
                border-radius: 96px;
                margin: 72px 0 23px;
            }

            @media @laptop {
                padding: 8px;
                border-radius: 56px;
                margin: 44px 0 16px;
            }

            @media @laptop--small {
                padding: 6px;
                border-radius: 40px;
                margin: 32px 0 16px;
            }

            @media @phone {
                padding: 8px;
                margin: 16px 0 16px;
            }
        }

        &-social {
            display: flex;

            button {
                flex: 1;
                height: 44px;
                background: @color-gray--light;
                border-radius: 70px;

                @media @desktop--big {
                    height: 60px;
                    border-radius: 96px;
                }

                @media @laptop {
                    height: 32px;
                    border-radius: 56px;
                }

                @media @laptop--small {
                    height: 24px;
                    border-radius: 40px;
                }

                @media @phone {
                    height: 36px;
                }

                &:not(:last-child) {
                    margin-right: 9px;

                    @media @phone {
                        margin-right: 6px;
                    }
                }
            }
        }

        &-footer {
            position: absolute;
            left: 0;
            bottom: 24px;
            width: 100%;
            padding: 24px 40px 0;
            border-top: 1px solid @color-gray--dark;

            @media @desktop--big {
                bottom: 32px;
                padding-top: 32px;
            }

            @media @laptop {
                bottom: 20px;
                padding-top: 20px;
            }

            @media @laptop--small {
                bottom: 16px;
                padding: 16px 28px 0;
            }

            @media @phone {
                bottom: 40px;
                padding: 16px 24px 0;
            }

            &Content {
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;

                @media @phone {
                    display: block;
                }
            }

            a {
                &:not(:first-child) {
                    @media @bigger-then-phone {
                        margin-left: 48px;
                    }
                    @media @desktop--big {
                        margin-left: 64px;
                    }

                    @media @laptop--small {
                        margin-left: 32px;
                    }
                }

                @media @phone {
                    display: block;
                }
            }
        }

        &-unti {
            display: flex;
            justify-content: center;
            align-items: center;

            span {
                color: black;
            }

            img {
                @media @desktop--big {
                    width: 44px;
                    height: 44px;
                    margin-left: 12px;
                }

                @media @desktop {
                    width: 36px;
                    height: 36px;
                    margin-left: 10px;
                }

                @media @laptop {
                    width: 32px;
                    height: 32px;
                    margin-left: 10px;
                }

                @media @laptop--small {
                    width: 24px;
                    height: 24px;
                    margin-left: 8px;
                }

                @media @phone {
                    width: 32px;
                    height: 32px;
                    margin-left: 8px;
                }
            }
        }
    }
</style>

export const DATA_LOADED_CHANGE = 'DATA_LOADED/CHANGE';
export const NOTIFICATIONS_VISIBILITY_TOGGLE = 'NOTIFICATIONS_VISIBILITY/TOGGLE';

export const NOTIFICATION_NEW_WEEK = 1;
export const NOTIFICATION_ENROLLMENT_START = 2;
export const NOTIFICATION_WEBINAR_DAY_BEFORE = 3;
export const NOTIFICATION_WEBINAR_HOUR_BEFORE = 4;
export const NOTIFICATION_WEBINAR_DATE_CHANGE = 5;
export const NOTIFICATION_HOMEWORK_STATUS_CHANGED = 6;
export const NOTIFICATION_HOMEWORK_DEADLINE_DAY_BEFORE = 7;
export const NOTIFICATION_ENROLLMENT_CLOSED = 8;
export const NOTIFICATION_NEW_HOMEWORKS = 9;
export const NOTIFICATION_HOMEWORK_REVIEW_DEADLINE_DAY_BEFORE = 10;
export const NOTIFICATION_HOMEWORK_REVIEW_DEADLINE_EXPIRED = 11;
export const NOTIFICATION_WEBINAR_RECORD = 12;
export const NOTIFICATION_ENROLLMENT_START_COORDINATOR = 13;
export const NOTIFICATION_ENROLLMENT_CLOSED_COORDINATOR = 14;
export const NOTIFICATION_HOMEWORK_REVIEW_DEADLINE_EXPIRED_COORDINATOR = 15;
export const NOTIFICATION_HOMEWORK_COMMENTED = 16;

export const NOTIFICATION_SETTINGS_KIND_LESSON = 1;
export const NOTIFICATION_SETTINGS_KIND_WEBINAR = 2;
export const NOTIFICATION_SETTINGS_KIND_HOMEWORK = 3;
export const NOTIFICATION_SETTINGS_KIND_COMMENT = 4;

export const NOTIFICATION_TITLES = {
    [NOTIFICATION_NEW_WEEK]: 'Доступны новые материалы курса «$name».',
    [NOTIFICATION_ENROLLMENT_START]: 'Началось обучение на курсе «$name».',
    [NOTIFICATION_WEBINAR_DAY_BEFORE]: 'Завтра в $datetime состоится вебинар «$name».',
    [NOTIFICATION_WEBINAR_HOUR_BEFORE]: 'Вебинар «$name» начинается через час.',
    [NOTIFICATION_WEBINAR_DATE_CHANGE]: 'Вебинар «$name» перенесен на $datetime.',
    [NOTIFICATION_WEBINAR_RECORD]: 'Доступна запись вебинара «$name».',
    [NOTIFICATION_HOMEWORK_STATUS_CHANGED]: 'Ваша работа к уроку «$name» проверена, посмотрите ответ преподавателя.',
    [NOTIFICATION_HOMEWORK_DEADLINE_DAY_BEFORE]: 'Завтра последний день сдачи домашней работы по уроку «$name».',
    [NOTIFICATION_ENROLLMENT_CLOSED]: {
        's': 'Курс «$name» завершен. Получите диплом об окончании.',
        't': 'Курс «$name» завершен.'
    },
    [NOTIFICATION_NEW_HOMEWORKS]: '$count_new по уроку «$name» $awaiting проверки.',
    [NOTIFICATION_HOMEWORK_REVIEW_DEADLINE_DAY_BEFORE]: 'Завтра последний день для проверки работ по уроку «$name».',
    [NOTIFICATION_HOMEWORK_REVIEW_DEADLINE_EXPIRED]: 'У $count по уроку «$name» просрочена проверка.',
    [NOTIFICATION_ENROLLMENT_START_COORDINATOR]: '',
    [NOTIFICATION_ENROLLMENT_CLOSED_COORDINATOR]: '',
    [NOTIFICATION_HOMEWORK_REVIEW_DEADLINE_EXPIRED_COORDINATOR]: '',
    [NOTIFICATION_HOMEWORK_COMMENTED]: '$author оставил комментарий к работе по уроку «$name».'
};

export const NOTIFICATION_SETTINGS_KIND_MAP = {
    [NOTIFICATION_SETTINGS_KIND_LESSON]: [
        NOTIFICATION_ENROLLMENT_START,
        NOTIFICATION_ENROLLMENT_CLOSED,
        NOTIFICATION_NEW_WEEK
    ],
    [NOTIFICATION_SETTINGS_KIND_WEBINAR]: [
        NOTIFICATION_WEBINAR_DATE_CHANGE,
        NOTIFICATION_WEBINAR_DAY_BEFORE,
        NOTIFICATION_WEBINAR_HOUR_BEFORE,
        NOTIFICATION_WEBINAR_RECORD
    ],
    [NOTIFICATION_SETTINGS_KIND_HOMEWORK]: [
        NOTIFICATION_HOMEWORK_STATUS_CHANGED,
        NOTIFICATION_HOMEWORK_DEADLINE_DAY_BEFORE,
        NOTIFICATION_HOMEWORK_REVIEW_DEADLINE_DAY_BEFORE,
        NOTIFICATION_HOMEWORK_REVIEW_DEADLINE_EXPIRED,
        NOTIFICATION_NEW_HOMEWORKS
    ],
    [NOTIFICATION_SETTINGS_KIND_COMMENT]: [
        NOTIFICATION_HOMEWORK_COMMENTED
    ]
};

export const NOTIFICATION_ROUTES = {
    [NOTIFICATION_NEW_WEEK]: 'course',
    [NOTIFICATION_ENROLLMENT_START]: 'course',
    [NOTIFICATION_WEBINAR_DAY_BEFORE]: 'webinar',
    [NOTIFICATION_WEBINAR_HOUR_BEFORE]: 'webinar',
    [NOTIFICATION_WEBINAR_DATE_CHANGE]: 'webinar',
    [NOTIFICATION_WEBINAR_RECORD]: 'webinar',
    [NOTIFICATION_HOMEWORK_STATUS_CHANGED]: 'lesson-work',
    [NOTIFICATION_HOMEWORK_DEADLINE_DAY_BEFORE]: 'lesson',
    [NOTIFICATION_ENROLLMENT_CLOSED]: 'course',
    [NOTIFICATION_NEW_HOMEWORKS]: 'statused-works',
    [NOTIFICATION_HOMEWORK_REVIEW_DEADLINE_DAY_BEFORE]: 'statused-works',
    [NOTIFICATION_HOMEWORK_REVIEW_DEADLINE_EXPIRED]: 'statused-works',
    [NOTIFICATION_ENROLLMENT_START_COORDINATOR]: 'course',
    [NOTIFICATION_ENROLLMENT_CLOSED_COORDINATOR]: 'course',
    [NOTIFICATION_HOMEWORK_REVIEW_DEADLINE_EXPIRED_COORDINATOR]: 'statused-works',
    [NOTIFICATION_HOMEWORK_COMMENTED]: 'lesson-work'
};

export const NOTIFICATION_DATETIME_FORMATS = {
    [NOTIFICATION_WEBINAR_DAY_BEFORE]: 'HH:mm',
    [NOTIFICATION_WEBINAR_DATE_CHANGE]: 'HH:mm d MMMM'
};

export const NOTIFICATIONS_SHOWN_TIMEOUT = 5000;

export const TIME_DIFF_NAMES = {
    hours: 'ч',
    days: 'д',
    weeks: 'н',
    minutes: 'м'
};

import {
    NAMESPACE,
    ITEM_FETCH,
    ITEM_SAVE,
    ITEM_PASSWORD,
    ITEM_RECOVER
} from './consts';

export default {
    [ITEM_FETCH](context, id) {
        return context.dispatch('sendMessage', {
            method: `${NAMESPACE}/${ITEM_FETCH}`,
            data: {
                id
            }
        }, {root: true});
    },
    [ITEM_SAVE](context, data) {
        const reader = new FileReader();
        const encoder = new TextEncoder();

        let resolve;

        context.state.uploading = new Promise(function (_resolve) {
            resolve = _resolve;
        });

        context.state.uploading.resolve = resolve;

        const info = JSON.stringify({
            'method': `${NAMESPACE}/${ITEM_SAVE}`,
            'data': {
                ...data,
                userpic: data.userpic instanceof File ? {
                    'name': data.userpic.name,
                    'size': data.userpic.size
                } : undefined
            }
        });

        if (data.userpic instanceof File) {
            reader.onload = (e) => {
                const encoded = encoder.encode(info);
                const sendBuffer = new ArrayBuffer(encoded.length + 1 + data.userpic.size);
                const buffer = new Uint8Array(sendBuffer);
                const fileBuffer = new Uint8Array(e.target.result);

                buffer.set(encoded);
                buffer.set(encoder.encode('B'), encoded.length);
                buffer.set(fileBuffer, encoded.length + 1);

                context.dispatch('sendBinary', buffer, {root: true});
            };

            reader.readAsArrayBuffer(data.userpic);
        } else {
            context.dispatch('sendMessage', {
                method: `${NAMESPACE}/${ITEM_SAVE}`,
                data
            }, {root: true});
        }

        return context.state.uploading;
    },
    [ITEM_PASSWORD](context, data) {
        return context.dispatch('sendMessage', {
            method: `${NAMESPACE}/${ITEM_PASSWORD}`,
            data: data
        }, {root: true});
    },
    [ITEM_RECOVER](context, data) {
        return context.dispatch('sendMessage', {
            method: `${NAMESPACE}/${ITEM_RECOVER}`,
            data: data
        }, {root: true});
    }
};

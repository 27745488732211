export const NAMESPACE = 'profile';

export const ITEM_FETCH = 'ITEM/FETCH';
export const ITEM_SAVE = 'ITEM/SAVE';

export const ITEM_PASSWORD = 'ITEM/PASSWORD';
export const ITEM_RECOVER = 'ITEM/RECOVER';

export const SOCIAL_FACEBOOK = 'fb';
export const SOCIAL_INSTAGRAM = 'in';
export const SOCIAL_BEHANCE = 'bh';
export const SOCIAL_DRIBBBLE = 'dr';
export const SOCIAL_WEBSITE = 'wb';

export const SOCIAL_NAMES_LIST = [SOCIAL_FACEBOOK, SOCIAL_INSTAGRAM, SOCIAL_BEHANCE, SOCIAL_DRIBBBLE, SOCIAL_WEBSITE];

export const SOCIAL_TITLES = {
    [SOCIAL_FACEBOOK]: 'Facebook',
    [SOCIAL_INSTAGRAM]: 'Instagram',
    [SOCIAL_BEHANCE]: 'Behance',
    [SOCIAL_DRIBBBLE]: 'Dribbble',
    [SOCIAL_WEBSITE]: 'Website'
};

export const NOTIFICATION_KIND_LESSONS = 1;
export const NOTIFICATION_KIND_WEBINARS = 2;
export const NOTIFICATION_KIND_HOMEWORKS = 3;

export const NOTIFICATION_KINDS_LIST = [
    NOTIFICATION_KIND_LESSONS,
    NOTIFICATION_KIND_WEBINARS,
    NOTIFICATION_KIND_HOMEWORKS
];

export const NOTIFICATION_TITLES = {
    [NOTIFICATION_KIND_LESSONS]: 'Доступ к новым урокам',
    [NOTIFICATION_KIND_WEBINARS]: 'Вебинары',
    [NOTIFICATION_KIND_HOMEWORKS]: 'Статусы домашних работ'
};

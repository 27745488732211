export const NAMESPACE = 'dashboard';

export const WEEKS_FETCH = 'WEEKS/FETCH';
export const WEEK_FETCH = 'WEEK/FETCH';
export const DASHBOARD_FETCH = 'DASHBOARD/FETCH';
export const DASHBOARD_ASYNC_FETCH = 'ASYNC/FETCH';


export const LESSON_TYPE_LECTURE = 1;
export const LESSON_TYPE_HOMEWORK = 2;
export const LESSON_TYPE_WEBINAR = 3;

export const LESSON_TYPE_DISPLAY = {
    [LESSON_TYPE_LECTURE]: 'Лекция',
    [LESSON_TYPE_HOMEWORK]: 'Урок',
    [LESSON_TYPE_WEBINAR]: 'Вебинар'
};

import {
    NAMESPACE,
    LIST_FETCH,
    ITEM_FETCH,
    LESSON_FETCH,
    LESSON_SESSION_ID,
    LESSON_SESSION_DATA,
    HOMEWORK_SEND,
    WEBINAR_FETCH,
    HOMEWORK_UPLOADING,
    HOMEWORK_UPDATE,
    LESSON_HOMEWORKS_LIST,
    LESSON_HOMEWORKS_FETCH,
    HOMEWORK_DOWNLOAD,
    HOMEWORKS_DOWNLOAD,
    DESIGN_LIBRARY_FETCH,
    LESSON_HOMEWORKS_VIEWED,
    VIEWED_GET,
    VIEWED_SET,
    HOMEWORK_CHAT_SEND,
    HOMEWORK_CHAT_MESSAGES,
    REACTION,
    REACTION_GET,
    REACTION_SET,
    TEST_SEND, LESSON_VIDEO_EVENT_SEND, VISITED_SET, SURVEY_SEND, VIDEO_PLAY
} from './consts';

// import binarypack from 'js-binarypack';
import { v4 as uuidv4 } from 'uuid';


// function blobToArrayBuffer(blob, cb) {
//     let fr = new FileReader();
//     fr.onload = function (evt) {
//         cb(evt.target.result);
//     };
//     fr.readAsArrayBuffer(blob);
// };

export default {
    [LIST_FETCH](context) {
        context.commit(LIST_FETCH, {data: []});

        return context.dispatch('sendMessage', {
            method: `${NAMESPACE}/${LIST_FETCH}`
        }, {root: true});
    },
    [ITEM_FETCH](context, data) {
        return context.dispatch('sendMessage', {
            method: `${NAMESPACE}/${ITEM_FETCH}`,
            data
        }, {root: true});
    },
    [LESSON_FETCH](context, data) {
        return context.dispatch('sendMessage', {
            method: `${NAMESPACE}/${LESSON_FETCH}`,
            data
        }, {root: true});
    },
    [LESSON_HOMEWORKS_LIST](context, {enrollmentId, lessonId}) {
        return context.dispatch('sendMessage', {
            method: `${NAMESPACE}/${LESSON_HOMEWORKS_LIST}`,
            data: {
                enrollmentId,
                lessonId
            }
        }, {root: true});
    },
    [HOMEWORK_SEND](context, data) {
        const files = data.files.map((file) => ({
            name: file.name,
            mimeType: file.type,
            size: file.size,
            uuid: uuidv4()
        }));

        context.commit(HOMEWORK_UPLOADING, {
            loading: true,
            created: false,
            files
        });

        context.dispatch('sendMessage', {
            method: `${NAMESPACE}/${HOMEWORK_SEND}`,
            data: {
                instance: data.instance,
                description: data.description,
                link: data.link
                // files
            }
        }, {root: true});
    },

    [HOMEWORK_UPLOADING](context, {file, index}) {
        // const uuid = context.state.uploading.files[index].uuid;
        const fileSize = context.state.uploading.files[index].size;

        const reader = new FileReader();
        const encoder = new TextEncoder();

        let resolve;

        context.state.uploading.files[index].promise = new Promise(function (_resolve) {
            resolve = _resolve;
        });

        context.state.uploading.files[index].promise.resolve = resolve;

        const info = JSON.stringify({
            'method': `${NAMESPACE}/${HOMEWORK_UPLOADING}`,
            'data': {
                submissionId: context.state.uploading.id,
                name: context.state.uploading.files[index].name,
                mimeType: context.state.uploading.files[index].mimeType,
                size: context.state.uploading.files[index].size,
                comments: context.state.uploading.files[index].comments || [],
                uuid: context.state.uploading.files[index].uuid
            }
        });

        const encoded = encoder.encode(info);

        reader.onload = (e) => {
            const sendBuffer = new ArrayBuffer(encoded.length + 1 + fileSize);
            const buffer = new Uint8Array(sendBuffer);
            const fileBuffer = new Uint8Array(e.target.result);

            buffer.set(encoded);
            buffer.set(encoder.encode('B'), encoded.length);
            buffer.set(fileBuffer, encoded.length + 1);

            return context.dispatch('sendBinary', buffer, {root: true});
        };

        reader.readAsArrayBuffer(file);

        return context.state.uploading.files[index].promise;
    },
    [LESSON_HOMEWORKS_FETCH](context, {enrollmentId, lessonId, userId}) {
        return context.dispatch('sendMessage', {
            method: `${NAMESPACE}/${LESSON_HOMEWORKS_FETCH}`,
            data: {
                enrollmentId,
                lessonId,
                userId
            }
        }, {root: true});
    },
    [HOMEWORK_UPDATE](context, data) {
        const files = data.filesToUpload.map((file) => ({
            name: file.name,
            mimeType: file.source.type,
            size: file.source.size,
            uuid: uuidv4(),
            comments: file.comments
        }));

        let resolve;

        context.state.uploading.promise = new Promise(function (_resolve) {
            resolve = _resolve;
        });

        context.state.uploading.promise.resolve = resolve;

        context.commit(HOMEWORK_UPLOADING, {
            loading: true,
            created: true,
            id: data.id,
            files
        });

        context.state.uploading.promise.then(() => {
            const _data = {
                ...data,
                files: [...context.state.homework.versions[0].files]
            };
            context.dispatch('sendMessage', {
                method: `${NAMESPACE}/${HOMEWORK_UPDATE}`,
                data: _data
            }, {root: true});
        });

        if (!files.length) {
            context.state.uploading.promise.resolve();
        }

        return context.state.uploading.promise;
    },
    [WEBINAR_FETCH](context, {enrollmentId, webinarId}) {
        return context.dispatch('sendMessage', {
            method: `${NAMESPACE}/${WEBINAR_FETCH}`,
            data: {
                enrollmentId,
                webinarId
            }
        }, {root: true});
    },
    [HOMEWORK_DOWNLOAD](context, submissionId) {
        return context.dispatch('sendMessage', {
            method: `${NAMESPACE}/${HOMEWORK_DOWNLOAD}`,
            data: {
                submissionId
            }
        }, {root: true});
    },
    [HOMEWORKS_DOWNLOAD](context, data) {
        return context.dispatch('sendMessage', {
            method: `${NAMESPACE}/${HOMEWORKS_DOWNLOAD}`,
            data
        }, {root: true});
    },
    [DESIGN_LIBRARY_FETCH](context) {
        context.commit(DESIGN_LIBRARY_FETCH, {data: []});

        return context.dispatch('sendMessage', {
            method: `${NAMESPACE}/${DESIGN_LIBRARY_FETCH}`
        }, {root: true});
    },
    [LESSON_HOMEWORKS_VIEWED](context, submissionId) {
        return context.dispatch('sendMessage', {
            method: `${NAMESPACE}/${LESSON_HOMEWORKS_VIEWED}`,
            data: {
                submissionId
            }
        }, {root: true});
    },
    [LESSON_SESSION_ID](context, {lessonId, lessonDuration}) {
        return context.dispatch('sendMessage', {
            method: `${NAMESPACE}/${LESSON_SESSION_ID}`,
            data: {
                lessonId,
                lessonDuration
            }
        }, {root: true});
    },
    [LESSON_SESSION_DATA](context, data) {
        return context.dispatch('sendMessage', {
            method: `${NAMESPACE}/${LESSON_SESSION_DATA}`,
            data
        }, {root: true});
    },
    [VIEWED_GET](context, data) {
        return context.dispatch('sendMessage', {
            method: `${NAMESPACE}/${VIEWED_GET}`,
            data
        }, {root: true});
    },
    [VIEWED_SET](context, data) {
        return context.dispatch('sendMessage', {
            method: `${NAMESPACE}/${VIEWED_SET}`,
            data
        }, {root: true});
    },
    [VISITED_SET](context, data) {
        return context.dispatch('sendMessage', {
            method: `${NAMESPACE}/${VISITED_SET}`,
            data
        }, {root: true});
    },
    [VIDEO_PLAY](context, data) {
        return context.dispatch('sendMessage', {
            method: `${NAMESPACE}/${VIDEO_PLAY}`,
            data
        }, {root: true});
    },
    [HOMEWORK_CHAT_SEND](context, data) {
        const reader = new FileReader();
        const encoder = new TextEncoder();

        let resolve;

        context.state.uploading.files[0] = new Promise(function (_resolve) {
            resolve = _resolve;
        });

        context.state.uploading.files[0].resolve = resolve;

        const info = JSON.stringify({
            'method': `${NAMESPACE}/${HOMEWORK_CHAT_SEND}`,
            'data': {
                ...data,
                filename: data.file instanceof File ? data.file.name : undefined,
                mimeType: data.file instanceof File ? data.file.type : undefined
            }
        });

        if (data.file instanceof File) {
            reader.onload = (e) => {
                const encoded = encoder.encode(info);
                const sendBuffer = new ArrayBuffer(encoded.length + 1 + data.file.size);
                const buffer = new Uint8Array(sendBuffer);
                const fileBuffer = new Uint8Array(e.target.result);

                buffer.set(encoded);
                buffer.set(encoder.encode('B'), encoded.length);
                buffer.set(fileBuffer, encoded.length + 1);

                context.dispatch('sendBinary', buffer, {root: true});
            };

            reader.readAsArrayBuffer(data.file);
        } else {
            return context.dispatch('sendMessage', {
                method: `${NAMESPACE}/${HOMEWORK_CHAT_SEND}`,
                data
            }, {root: true});
        }

        return context.state.uploading.files[0];
    },
    [HOMEWORK_CHAT_MESSAGES](context, data) {
        return context.dispatch('sendMessage', {
            method: `${NAMESPACE}/${HOMEWORK_CHAT_MESSAGES}`,
            data
        }, {root: true});
    },
    [REACTION_GET](context, { submissionId, reactions }) {
        return context.dispatch('sendMessage', {
            method: `${NAMESPACE}/${REACTION_GET}`,
            data: {
                submissionId,
                reactions: reactions || Object.values(REACTION)
            }
        }, {root: true});
    },
    [REACTION_SET](context, data) {
        return context.dispatch('sendMessage', {
            method: `${NAMESPACE}/${REACTION_SET}`,
            data
        }, {root: true});
    },
    [TEST_SEND](context, data) {
        return context.dispatch('sendMessage', {
            method: `${NAMESPACE}/${TEST_SEND}`,
            data
        }, { root: true });
    },
    [SURVEY_SEND](context, data) {
        return context.dispatch('sendMessage', {
            method: `${NAMESPACE}/${SURVEY_SEND}`,
            data
        }, { root: true });
    },
    [LESSON_VIDEO_EVENT_SEND](context, data) {
        return context.dispatch('sendMessage', {
            method: `${NAMESPACE}/${LESSON_VIDEO_EVENT_SEND}`,
            data
        }, { root: true });
    }
};

export const NAMESPACE = 'courses';

export const LIST_FETCH = 'LIST/FETCH';
export const ITEM_FETCH = 'ITEM/FETCH';

export const LESSON_FETCH = 'LESSON/FETCH';
export const LESSON_CLEAR = 'LESSON/CLEAR';

export const LESSON_SESSION_ID = 'LESSON/SESSION/ID';
export const LESSON_SESSION_DATA = 'LESSON/SESSION/DATA';

export const WEBINAR_FETCH = 'WEBINAR/FETCH';
export const WEBINAR_CLEAR = 'WEBINAR/CLEAR';

export const HOMEWORK_SEND = 'HOMEWORK/SEND';
export const HOMEWORK_UPLOADING = 'HOMEWORK/UPLOADING';
export const HOMEWORK_UPDATE = 'HOMEWORK/UPDATE';
export const HOMEWORK_CLEAR = 'HOMEWORK/CLEAR';

export const LESSON_HOMEWORKS_LIST = 'LESSON/HOMEWORKS/LIST';
export const LESSON_HOMEWORKS_FETCH = 'LESSON/HOMEWORKS/FETCH';

export const HOMEWORK_DOWNLOAD = 'HOMEWORK/DOWNLOAD';
export const HOMEWORKS_DOWNLOAD = 'HOMEWORKS/DOWNLOAD';

export const DESIGN_LIBRARY_FETCH = 'DESIGNLIBRARY/FETCH';

export const HOMEWORK_COMMENT_SAVE = 'HOMEWORK/COMMENT/SAVE';
export const HOMEWORK_COMMENT_REMOVE = 'HOMEWORK/COMMENT/REMOVE';

export const HOMEWORK_ADD_FILE = 'HOMEWORK/FILE/ADD';

export const LESSON_HOMEWORKS_VIEWED = 'LESSON/HOMEWORKS/VIEWED';

export const HOMEWORK_CHAT_SEND = 'HOMEWORK/CHAT';
export const HOMEWORK_CHAT_MESSAGES = 'HOMEWORK/COMMENTS';


export const VIEWED_GET = 'VIEWED/GET';
export const VIEWED_SET = 'VIEWED/SET';

/**
 * Lesson Page visited event name
 * @type {string}
 */
export const VISITED_SET = 'VISITED/SET';

export const VIDEO_PLAY = 'VIDEO/PLAY';

export const REACTION_GET = 'REACTION/GET';
export const REACTION_SET = 'REACTION/SET';

export const TEST_SEND = 'TEST/SEND';
export const SURVEY_SEND = 'SURVEY/SEND';

export const LESSON_VIDEO_EVENT_SEND = 'LESSON/VIDEO/EVENT/SEND';

export const WEEK_DAY_FORMS = {
    1: 'понедельника',
    2: 'вторника',
    3: 'среды',
    4: 'четверга',
    5: 'пятницы',
    6: 'субботы',
    7: 'воскресенья'
};

export const LESSON_KINDS_INFO = {
    1: {
        type: 'lecture',
        title: 'Лекция'
    },
    2: {
        type: 'webinar',
        title: 'Вебинар'
    },
    3: {
        type: 'lesson',
        title: 'Урок'
    }
};

export const LESSON_KINDS = {
    lecture: 1,
    webinar: 2,
    lesson: 3
};

export const LESSON_PAGE_KINDS = {
    lesson: 'l',
    test: 't',
    survey: 's'
};

export const WEBINAR_STATUS = {
    Planned: 1,
    Current: 2,
    Finished : 3
};

export const WEBINAR_STATUS_DISPLAY = {
    [WEBINAR_STATUS.Planned]: 'Запланирован',
    [WEBINAR_STATUS.Current]: 'Идет',
    [WEBINAR_STATUS.Finished]: 'Завершился'
};

export const COURSE_STATUS = {
    Active: 1,
    Future: 2,
    Past: 3
};

export const COURSE_STATUS_DISPLAY = {
    [COURSE_STATUS.Active]: {
        single: 'Активный',
        multiple: 'Активные',
        name: 'active'
    },
    [COURSE_STATUS.Future]: {
        single: 'Предстоящий',
        multiple: 'Предстоящие',
        name: 'future'
    },
    [COURSE_STATUS.Past]: {
        single: 'Завершенный',
        multiple: 'Завершенные',
        name: 'past'
    }
};

export const DESIGN_LIBRARY_STATUS = {
    All: 1,
    Started: 2,
    New: 3
};

export const DESIGN_LIBRARY_STATUS_DISPLAY = {
    [DESIGN_LIBRARY_STATUS.All]: 'Все',
    [DESIGN_LIBRARY_STATUS.Started]: 'Начатые',
    [DESIGN_LIBRARY_STATUS.New]: 'Непройденные'
};

export const DESIGN_LIBRARY_CATEGORY_FREE = 'free';

export const FILE_KINDS = {
    image: 1,
    video: 2,
    other: 3
};

export const WORK_STATUSES = {
    review: 1,
    revision: 2,
    accepted: 3,
    unuploaded: 4
};

export const WORK_STATUSES_DISPLAY = {
    [WORK_STATUSES.review]: {
        name: 'На проверке',
        multiple: 'На проверку',
        field: 'review'
    },
    [WORK_STATUSES.revision]: {
        name: 'На доработке',
        multiple: 'На доработке',
        editor: 'На доработку',
        field: 'revision'
    },
    [WORK_STATUSES.accepted]: {
        name: 'Принята',
        multiple: 'Принятые',
        editor: 'Работа принята',
        field: 'accepted'
    },
    [WORK_STATUSES.unuploaded]: {
        name: 'Не сдана',
        multiple: 'Не сдали',
        field: 'unuploaded'
    }
};

export const STATUS_TYPE_COMMON = 'common';
export const STATUS_TYPE_ERROR = 'error';
export const STATUS_TYPE_SUCCESS = 'success';
export const STATUS_TYPE_LOADING = 'loading';

export const STATUS_HIDE_TIMEOUT = 5000;

export const FILE_SIZE_LIMIT = 50000000;
export const MEGA_BYTE = 1000000;

export const MIN_COMMENT_EDITOR_LINES = 2;
export const MAX_COMMENT_EDITOR_LINES = 5;


export const REACTION = {
    Heart: 'heart',
    Smile: 'smile',
    Fire: 'fire',
    Wow: 'wow'
};

export const EMOJI_SETTINGS = {
    [REACTION.Heart]: {
        symbol: '&#128150;'
    },
    [REACTION.Smile]: {
        symbol: '&#128516;'
    },
    [REACTION.Fire]: {
        symbol: '&#128293;'
    },
    [REACTION.Wow]: {
        symbol: '&#128561;'
    }
};


export const VIDEO_TYPE = {
    lesson: 'lesson',
    homework: 'homework'
};

import {
    NAMESPACE,
    WEEKS_FETCH,
    WEEK_FETCH,
    DASHBOARD_FETCH, DASHBOARD_ASYNC_FETCH
} from './consts';

export default {
    [WEEKS_FETCH](context) {
        return context.dispatch('sendMessage', {
            method: `${NAMESPACE}/${WEEKS_FETCH}`
        }, {root: true});
    },
    [WEEK_FETCH](context, date) {
        return context.dispatch('sendMessage', {
            method: `${NAMESPACE}/${WEEK_FETCH}`,
            data: {
                date: date.toISODate()
            }
        }, {root: true});
    },
    [DASHBOARD_FETCH](context) {
        return context.dispatch('sendMessage', {
            method: `${NAMESPACE}/${DASHBOARD_FETCH}`
        }, {root: true});
    },
    [DASHBOARD_ASYNC_FETCH](context) {
        return context.dispatch('sendMessage', {
            method: `${NAMESPACE}/${DASHBOARD_ASYNC_FETCH}`
        }, {root: true});
    }
};

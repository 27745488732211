<template>
    <div class="Logo" :class="[mods.map(mod => `Logo--${mod}`), {isMobileDark: mobileDark}]">
        <div class="Logo-circle"></div>
        <div class="Logo-title">BBE</div>
    </div>
</template>

<script>
    export default {
        name: 'Logo',
        props: {
            mods: {
                type: Array,
                default: () => []
            },
            mobileDark: Boolean
        }
    };
</script>

<style lang="less">
    @import "~styles/const.less";

    .Logo {
        display: flex;
        align-items: center;

        &--dark {
            .Logo-circle {
                background: @color-black;
            }

            .Logo-title {
                color: @color-black;
            }
        }

        &.isMobileDark {
            @media @phone {
                .Logo-circle {
                    background: @color-black;
                }

                .Logo-title {
                    color: @color-black;
                }
            }
        }

        &-circle {
            width: 36px;
            height: 36px;
            margin-right: 11px;
            background: @color-gray;
            border-radius: 50%;

            @media @desktop--big {
                width: 40px;
                height: 40px;
                margin-right: 11px;
            }

            @media @laptop {
                width: 32px;
                height: 32px;
                margin-right: 10px;
            }

            @media @laptop--small {
                width: 24px;
                height: 24px;
                margin-right: 8px;
            }

            @media @phone {
                width: 36px;
                height: 36px;
            }
        }

        &-title {
            width: 90px;
            font-size: 32px;
            line-height: 32px;
            font-weight: 700;

            @media @desktop--big {
                width: 120px;
                font-size: 32px;
                line-height: 32px;
            }

            @media @desktop {
                width: 120px;
                font-size: 28px;
                line-height: 28px;
            }

            @media @laptop {
                width: 66px;
                font-size: 26px;
                line-height: 26px;
            }

            @media @laptop--small {
                width: 50px;
                font-size: 20px;
                line-height: 20px;
            }

            @media @phone {
                display: none;
            }
        }
    }
</style>
